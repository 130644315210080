import { useEffect } from 'react';

import { SourceSystem } from 'daos/external_integration_enums';
import { LpSvg, LpSvgs } from 'features/common/lp_svg';
import { avoClient } from 'lib/avo/client';
import { ProductName } from 'lib/use_product_name';

const svgPixelSize = 584;
const { segmentKey } = window as any;

const WelcomeSection = () => {
  useEffect(() => {
    if (segmentKey) {
      avoClient?.integrationStarted({
        integrationName: SourceSystem.JIRA,
      });
    }
  }, []);

  return (
    <div className="jira-project-modal__content__welcome">
      <LpSvg icon={LpSvgs.JIRA_SOFTWARE} size={svgPixelSize} />
      <div className="jira-project-modal__content__welcome-text" data-testid="jiraWelcomeText">
        You are just a few steps away from integrating <ProductName /> with Jira.
      </div>
    </div>
  );
};

export default WelcomeSection;
