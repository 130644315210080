import { useFormikContext } from 'formik';
import { Table } from 'semantic-ui-react';

import { LpFormikDatePicker } from 'features/common/forms/formik/date';
import { JiraProjectModalInfo } from 'features/jira_project/modal/common/jira_project_modal_info';
import { JiraProjectModalTableDeleteCell } from 'features/jira_project/modal/common/jira_project_modal_table_delete_cell';
import { JiraProjectModalMultiPicklistCell } from 'features/jira_project/modal/common/jira_project_modal_table_multi_picklist_cell';
import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { JiraProjectModalTablePicklistCell } from 'features/jira_project/modal/common/jira_project_modal_table_picklist_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import { JiraProjectTable } from 'features/jira_project/modal/common/jira_project_table';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { JiraProjectFiltersMultiPicklist } from 'features/jira_project/modal/sections/project_and_issues/jira_project_filters_multi_picklist';
import { ProjectIdPicklist } from 'features/jira_project/modal/sections/project_and_issues/jira_project_picklist';
import { JiraProjectModalFormFields, JiraProjectModalFormValues } from 'features/jira_project/modal/types';
import 'features/jira_project/modal/jira_project_modal.scss';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';

import ParentFilterMultiInput from './jira_parent_filter_multi_input';

const ProjectAndIssues = () => {
  const hasJiraExpandedFiltering = useHasFeature(FeatureFlag.jiraExpandedFiltering);
  const { values, setFieldValue, errors } = useFormikContext<JiraProjectModalFormValues>();
  const { setJiraProjectName, isEditMode, jiraProjectDetails, canModify } = useJiraProjectModalContext();

  const selectedJiraIssueTypeIds = values[JiraProjectModalFormFields.IssueTypeIds];
  const selectedJiraStatusIds = values[JiraProjectModalFormFields.IssueStatusIds];
  const selectedParentIssueIdsOrKeys = values[JiraProjectModalFormFields.ParentIssueIdsOrKeys];
  const selectedJiraDateField = values[JiraProjectModalFormFields.IssueCreatedDate];
  const selectedJiraProjectId = values[JiraProjectModalFormFields.JiraProjectId];

  const handleDeleteButtonClick = (field: JiraProjectModalFormFields) => {
    switch (field) {
      case JiraProjectModalFormFields.JiraProjectId:
        setFieldValue(JiraProjectModalFormFields.JiraProjectId, '');
        setJiraProjectName('');
        return;
      case JiraProjectModalFormFields.IssueTypeIds:
        setFieldValue(JiraProjectModalFormFields.IssueTypeIds, []);
        return;
      case JiraProjectModalFormFields.IssueStatusIds:
        setFieldValue(JiraProjectModalFormFields.IssueStatusIds, []);
        return;
      case JiraProjectModalFormFields.ParentIssueIdsOrKeys:
        setFieldValue(JiraProjectModalFormFields.ParentIssueIdsOrKeys, []);
        return;
      case JiraProjectModalFormFields.IssueCreatedDate:
        setFieldValue(JiraProjectModalFormFields.IssueCreatedDate, '');
        return;
      default:
        return;
    }
  };

  return (
    <>
      <JiraProjectModalInfo
        title="Project & Issues Filter"
        helperText={
          isEditMode
            ? 'Use filters to select which issues are imported'
            : 'Choose a Jira project and then use filters to select which issues are imported'
        }
      />
      <JiraProjectTable>
        <JiraProjectModalTableRow>
          <JiraProjectModalTableNameCell content="Jira Project*" />
          <JiraProjectModalTablePicklistCell picklist={<ProjectIdPicklist />} />
          <JiraProjectModalTableDeleteCell
            enabled={!!selectedJiraProjectId && !isEditMode}
            onClick={() => handleDeleteButtonClick(JiraProjectModalFormFields.JiraProjectId)}
          />
        </JiraProjectModalTableRow>
        <JiraProjectModalTableRow>
          <JiraProjectModalTableNameCell content="Issue Type*" />
          <JiraProjectModalMultiPicklistCell>
            <JiraProjectFiltersMultiPicklist
              formValueType={JiraProjectModalFormFields.IssueTypeIds}
              options={jiraProjectDetails?.types ?? []}
            />
          </JiraProjectModalMultiPicklistCell>
          <JiraProjectModalTableDeleteCell
            enabled={canModify && !!selectedJiraIssueTypeIds?.length}
            onClick={() => handleDeleteButtonClick(JiraProjectModalFormFields.IssueTypeIds)}
          />
        </JiraProjectModalTableRow>

        <JiraProjectModalTableRow>
          <JiraProjectModalTableNameCell content="Issue Status*" />
          <JiraProjectModalMultiPicklistCell>
            <JiraProjectFiltersMultiPicklist
              formValueType={JiraProjectModalFormFields.IssueStatusIds}
              options={jiraProjectDetails?.statuses ?? []}
            />
          </JiraProjectModalMultiPicklistCell>
          <JiraProjectModalTableDeleteCell
            enabled={canModify && !!selectedJiraStatusIds?.length}
            onClick={() => handleDeleteButtonClick(JiraProjectModalFormFields.IssueStatusIds)}
          />
        </JiraProjectModalTableRow>
        {hasJiraExpandedFiltering && (
          <JiraProjectModalTableRow>
            <JiraProjectModalTableNameCell content="Parent Issue Key" />
            <Table.Cell>
              <ParentFilterMultiInput />
            </Table.Cell>
            <JiraProjectModalTableDeleteCell
              enabled={canModify && !!selectedParentIssueIdsOrKeys.length}
              onClick={() => handleDeleteButtonClick(JiraProjectModalFormFields.ParentIssueIdsOrKeys)}
            />
          </JiraProjectModalTableRow>
        )}

        <JiraProjectModalTableRow>
          <JiraProjectModalTableNameCell content="Include issues created after*" />
          <Table.Cell>
            <LpFormikDatePicker
              error={errors[JiraProjectModalFormFields.IssueCreatedDate]}
              name={JiraProjectModalFormFields.IssueCreatedDate}
              disabled={!canModify && !!selectedJiraProjectId}
            />
          </Table.Cell>
          <JiraProjectModalTableDeleteCell
            enabled={canModify && !!selectedJiraDateField}
            onClick={() => handleDeleteButtonClick(JiraProjectModalFormFields.IssueCreatedDate)}
          />
        </JiraProjectModalTableRow>
      </JiraProjectTable>
    </>
  );
};

export default ProjectAndIssues;
