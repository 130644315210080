import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Form, Button, Input } from 'semantic-ui-react';

import { JiraProjectModalFormFields, JiraProjectModalFormValues } from 'features/jira_project/modal/types';

const ParentFiltersMultiInput = () => {
  const { values, setFieldValue } = useFormikContext<JiraProjectModalFormValues>();
  const [inputValues, setInputValues] = useState<Array<string>>(
    values[JiraProjectModalFormFields.ParentIssueIdsOrKeys] || ['']
  );

  useEffect(() => {
    //  make sure we always have a blank input field at the end
    const lastInputValue = inputValues[inputValues.length - 1];
    if (lastInputValue && lastInputValue.trim() !== '') {
      setInputValues((prevInputValues) => [...(prevInputValues || []), '']);
    }

    if (inputValues.length === 0) {
      setInputValues(['']);
    }
  }, [inputValues]);

  const handleInputChange = (index: number, newValue: string) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = newValue;
    setInputValues(newInputValues);
  };

  const handleInputBlur = (index: number, newValue: string) => {
    const nonBlankValues = inputValues.filter((value) => value.trim() !== '');
    setFieldValue(JiraProjectModalFormFields.ParentIssueIdsOrKeys, nonBlankValues);

    // Automatically add a new input field if the current one is filled
    if (newValue && index === inputValues.length - 1) {
      setInputValues([...inputValues, '']);
    }
  };

  const handleRemoveInput = (index: number, event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const newInputValues = inputValues.filter((_, i) => i !== index);
    setInputValues(newInputValues);

    const nonBlankValues = newInputValues.filter((value) => value.trim() !== '');
    setFieldValue(JiraProjectModalFormFields.ParentIssueIdsOrKeys, nonBlankValues);
  };

  return (
    <div>
      {inputValues.map((value, index) => (
        <Form.Field key={index}>
          <Input
            value={value}
            onChange={(e) => handleInputChange(index, e.target.value)}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleInputBlur(index, e.target.value)}
            action={
              index !== inputValues.length - 1 ? (
                <Button icon="delete" onClick={(e) => handleRemoveInput(index, e)} />
              ) : null
            }
          />
        </Form.Field>
      ))}
    </div>
  );
};

export default ParentFiltersMultiInput;
