// Generated by Avo VERSION 163.80.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* eslint-disable */
// @ts-nocheck

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function () {
        try {
          new Blob();
          return true;
        } catch (e) {
          return false;
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self,
  };

  function isDataView(obj: any) {
    return obj && DataView.prototype.isPrototypeOf(obj);
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]',
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function (obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1;
      };
  }

  function normalizeName(name: any) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name');
    }
    return name.toLowerCase();
  }

  function normalizeValue(value: any) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value;
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items: any) {
    var iterator: any = {
      next: function () {
        var value = items.shift();
        return { done: value === undefined, value: value };
      },
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function () {
        return iterator;
      };
    }

    return iterator;
  }

  function Headers(headers: any) {
    // @ts-ignore
    (this as any).map = {};

    if (headers instanceof Headers) {
      (headers as any).forEach(function (value: any, name: any) {
        // @ts-ignore
        this.append(name, value);
        // @ts-ignore
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function (header: any) {
        // @ts-ignore
        this.append(header[0], header[1]);
        // @ts-ignore
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function (name: any) {
        // @ts-ignore
        this.append(name, headers[name]);
        // @ts-ignore
      }, this);
    }
  }

  Headers.prototype.append = function (name: any, value: any) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function (name: any) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function (name: any) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null;
  };

  Headers.prototype.has = function (name: any) {
    return this.map.hasOwnProperty(normalizeName(name));
  };

  Headers.prototype.set = function (name: any, value: any) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function (callback: any, thisArg: any) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function () {
    var items: any = [];
    this.forEach(function (_value: any, name: any) {
      items.push(name);
    });
    return iteratorFor(items);
  };

  Headers.prototype.values = function () {
    var items: any = [];
    this.forEach(function (value: any) {
      items.push(value);
    });
    return iteratorFor(items);
  };

  Headers.prototype.entries = function () {
    var items: any = [];
    this.forEach(function (value: any, name: any) {
      items.push([name, value]);
    });
    return iteratorFor(items);
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body: any) {
    if (body.bodyUsed) {
      return true;
    }
    body.bodyUsed = true;
    return false;
  }

  function fileReaderReady(reader: any) {
    return new Promise(function (resolve: any, reject: any) {
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function () {
        reject(reader.error);
      };
    });
  }

  function readBlobAsArrayBuffer(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise;
  }

  function readBlobAsText(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise;
  }

  function readArrayBufferAsText(buf: any) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]!);
    }
    return chars.join('');
  }

  function bufferClone(buf: any) {
    if (buf.slice) {
      return buf.slice(0);
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer;
    }
  }

  function Body() {
    // @ts-ignore
    (this as any).bodyUsed = false;

    // @ts-ignore
    (this as any)._initBody = function (body: any) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      // @ts-ignore
      (this as any).blob = function () {
        var rejected = consumed(this);
        if (rejected) {
          return Promise.reject(new TypeError('Already read'));
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob);
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]));
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob');
        } else {
          return Promise.resolve(new Blob([this._bodyText]));
        }
      };

      // @ts-ignore
      (this as any).arrayBuffer = function () {
        if (this._bodyArrayBuffer) {
          if (consumed(this)) {
            return Promise.reject(new TypeError('Already read'));
          } else {
            return Promise.resolve(this._bodyArrayBuffer);
          }
        } else {
          return this.blob().then(readBlobAsArrayBuffer);
        }
      };
    }

    // @ts-ignore
    (this as any).text = function () {
      var rejected = consumed(this);
      if (rejected) {
        return Promise.reject(new TypeError('Already read'));
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob);
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer));
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text');
      } else {
        return Promise.resolve(this._bodyText);
      }
    };

    if (support.formData) {
      // @ts-ignore
      (this as any).formData = function () {
        return this.text().then(decode);
      };
    }

    // @ts-ignore
    (this as any).json = function () {
      return this.text().then(JSON.parse);
    };

    // @ts-ignore
    return this;
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method: any) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method;
  }

  function Request(input: any, options: any) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if ((input as any).bodyUsed) {
        throw new TypeError('Already read');
      }
      // @ts-ignore
      (this as any).url = (input as any).url;
      // @ts-ignore
      this.credentials = (input as any).credentials;
      if (!options.headers) {
        // @ts-ignore
        this.headers = new Headers((input as any).headers);
      }
      // @ts-ignore
      this.method = (input as any).method;
      // @ts-ignore
      this.mode = (input as any).mode;
      // @ts-ignore
      this.signal = (input as any).signal;
      if (!body && (input as any)._bodyInit != null) {
        body = (input as any)._bodyInit;
        (input as any).bodyUsed = true;
      }
    } else {
      // @ts-ignore
      this.url = String(input);
    }

    // @ts-ignore
    this.credentials = options.credentials || this.credentials || 'same-origin';
    // @ts-ignore
    if (options.headers || !this.headers) {
      // @ts-ignore
      this.headers = new Headers(options.headers);
    }
    // @ts-ignore
    this.method = normalizeMethod(options.method || this.method || 'GET');
    // @ts-ignore
    this.mode = options.mode || this.mode || null;
    // @ts-ignore
    this.signal = options.signal || this.signal;
    // @ts-ignore
    this.referrer = null;

    // @ts-ignore
    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests');
    }
    // @ts-ignore
    this._initBody(body);
  }

  Request.prototype.clone = function () {
    // @ts-ignore
    return new Request(this, { body: this._bodyInit });
  };

  function decode(body: any) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function (bytes: any) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form;
  }

  function parseHeaders(rawHeaders: any) {
    // @ts-ignore
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function (line: any) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers;
  }

  Body.call(Request.prototype);

  function Response(bodyInit: any, options: any) {
    if (!options) {
      options = {};
    }

    // @ts-ignore
    this.type = 'default';
    // @ts-ignore
    this.status = options.status === undefined ? 200 : options.status;
    // @ts-ignore
    this.ok = this.status >= 200 && this.status < 300;
    // @ts-ignore
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    // @ts-ignore
    this.headers = new Headers(options.headers);
    // @ts-ignore
    this.url = options.url || '';
    // @ts-ignore
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function () {
    // @ts-ignore
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      // @ts-ignore
      headers: new Headers(this.headers),
      url: this.url,
    });
  };

  Response.error = function () {
    // @ts-ignore
    var response = new Response(null, { status: 0, statusText: '' });
    response.type = 'error';
    return response;
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function (url: any, status: any) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code');
    }

    // @ts-ignore
    return new Response(null, { status: status, headers: { location: url } });
  };

  (self as any).DOMException = (self as any).DOMException;
  try {
    new (self as any).DOMException();
  } catch (err) {
    (self as any).DOMException = function (message: any, name: any) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    (self as any).DOMException.prototype = Object.create(Error.prototype);
    (self as any).DOMException.prototype.constructor = (self as any).DOMException;
  }

  function fetch(input: any, init: any) {
    return new Promise(function (resolve, reject) {
      // @ts-ignore
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new (self as any).DOMException('Aborted', 'AbortError'));
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function () {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
        };
        (options as any).url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
        // @ts-ignore
        resolve(new Response(body, options));
      };

      xhr.onerror = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function () {
        reject(new (self as any).DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function (value: any, name: any) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function () {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    });
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    (self as any).fetch = fetch;
    (self as any).Headers = Headers;
    (self as any).Request = Request;
    (self as any).Response = Response;
  }
})();

export enum AvoEnv {
  Prod = 'prod',
  Staging = 'staging',
  Dev = 'dev',
}

export interface CustomDestination {
  make?(env: AvoEnv, apiKey: string): void;
  logEvent?: (eventName: string, eventProperties: Record<string, any>) => void;
  setUserProperties?: (userId: string, userProperties: Record<string, any>) => void;
  identify?: (userId: string) => void;
  unidentify?: () => void;
  logPage?: (pageName: string, eventProperties: Record<string, any>) => void;
  revenue?: (amount: number, eventProperties: Record<string, any>) => void;
  setGroupProperties?: (groupType: string, groupId: string, groupProperties: Record<string, any>) => void;
  addCurrentUserToGroup?: (groupType: string, groupId: string, groupProperties: Record<string, any>) => void;
  logEventWithGroups?: (eventName: string, eventProperties: any, groupTypesToGroupIds: Record<string, string>) => void;
}

// @ts-ignore
interface AvoAssertMessage {
  eventName?: string;
  tag?: string;
  propertyId?: string;
  message?: string;
  additionalProperties?: string[];
  shape?: any;
  shapeUserProps?: any;
  actualType?: string;
}

let __AVO_ENV__: AvoEnv | null = null;
// @ts-ignore
let __AVO_NOOP__: boolean = false;
// @ts-ignore
let __AVO_LOGGER__: AvoLogger | null = null;
// @ts-ignore
let __STRICT__: boolean | null = null;
// @ts-ignore
let __REPORT_FAILURE_AS__: 'error' | 'warn' | 'log' | null = null;

// @ts-ignore
let __WEB_DEBUGGER__: boolean = true;
export const avoInspectorApiKey = '2U27VM8WsxRLaI9U2ENy';
// @ts-ignore
interface AvoInspector {}
let __INSPECTOR__: AvoInspector | null = null;

// polyfill Object.assign
// @ts-ignore
declare interface ObjectConstructor {
  assign: any;
}
// @ts-ignore
if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, 'assign', {
    value: function assign(target: any, _varArgs: any) {
      // .length of function is 2
      if (target == null) {
        // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      let to = Object(target);

      for (let index = 1; index < arguments.length; index++) {
        let nextSource = arguments[index];

        if (nextSource != null) {
          // Skip over if undefined or null
          for (let nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true,
  });
}

interface AvoLogger {
  logDebug(env: AvoEnv | null, message: string): boolean;
  logWarn(env: AvoEnv | null, message: string): boolean;
  logError(env: AvoEnv | null, error: string): boolean;
}

enum webDebuggerArea {
  BottomRight = 'BottomRight',
  BottomLeft = 'BottomLeft',
  TopRight = 'TopRight',
  TopLeft = 'TopLeft',
}

interface bottomRightParameters {
  bottom: number;
  right: number;
}

interface bottomLeftParameters {
  bottom: number;
  left: number;
}

interface topRightParameters {
  top: number;
  right: number;
}

interface topLeftParameters {
  top: number;
  left: number;
}

interface webDebuggerPosition {
  position: webDebuggerArea;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface webDebuggerOptions {
  position?: webDebuggerPosition;
}

interface WebDebuggerPositionSetter {
  BottomRight(p: bottomRightParameters): webDebuggerPosition;
  BottomLeft(p: bottomLeftParameters): webDebuggerPosition;
  TopRight(p: topRightParameters): webDebuggerPosition;
  TopLeft(p: topLeftParameters): webDebuggerPosition;
}

export const WebDebuggerPosition: WebDebuggerPositionSetter = {
  BottomRight: ({ bottom, right }) => ({
    position: webDebuggerArea.BottomRight,
    bottom,
    right,
  }),
  BottomLeft: ({ bottom, left }) => ({
    position: webDebuggerArea.BottomLeft,
    bottom,
    left,
  }),
  TopRight: ({ top, right }) => ({
    position: webDebuggerArea.TopRight,
    top,
    right,
  }),
  TopLeft: ({ top, left }) => ({
    position: webDebuggerArea.TopLeft,
    top,
    left,
  }),
};

let InternalAvoLogger: any = {
  logEventSent: function logEventSent(eventName: string, eventProperties: any, userProperties: any) {
    const message =
      'Event Sent:' +
      eventName +
      'Event Props:' +
      JSON.stringify(eventProperties) +
      'User Props:' +
      JSON.stringify(userProperties);

    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return;
    }
    typeof console !== 'undefined' &&
      console.log('[avo] Event Sent:', eventName, 'Event Props:', eventProperties, 'User Props:', userProperties);
  },

  log: function log(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return;
    }
    typeof console !== 'undefined' && console.log('[avo] ' + message);
  },

  warn: function warn(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
      return;
    }
    typeof console !== 'undefined' && console.warn('[avo] ' + message);
  },

  error: function error(message: string, error: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
      return;
    }
    typeof console !== 'undefined' && console.error('[avo] ' + message, error);
  },
};

function convertPropertiesArrayToMap(propertiesArray: [{ id: string; name: string; value: string }]): {
  string: string | null | undefined;
} {
  let result: { string: string } = {};

  propertiesArray.forEach((value) => {
    result[value.name] = value.value;
  });

  return result;
}

// @ts-ignore
let array_difference: any;
// @ts-ignore
let AvoAssert: any;
array_difference = function array_difference(a1: any[], a2: any[]) {
  let result: any[] = [];
  for (let i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
};

AvoAssert = {
  assertObject: function assertObject(propertyId: string, propName: string, obj: any) {
    if (typeof obj !== 'object') {
      let message =
        propName +
        ' should be of type object but you provided type ' +
        typeof obj +
        ' with value ' +
        JSON.stringify(obj);
      return [{ tag: 'expectedObjectType', propertyId, message, actualType: typeof obj }];
    } else {
      return [];
    }
  },

  assertString: function assertString(propertyId: string, propName: string, str: string) {
    if (typeof str !== 'string') {
      let message =
        propName +
        ' should be of type string but you provided type ' +
        typeof str +
        ' with value ' +
        JSON.stringify(str);
      return [{ tag: 'expectedStringType', propertyId, message, actualType: typeof str }];
    } else {
      return [];
    }
  },

  assertInt: function assertInt(propertyId: string, propName: string, int: number) {
    if (typeof int === 'number' && int !== Math.round(int)) {
      let message = propName + ' should be of type int but you provided type float with value ' + JSON.stringify(int);
      return [{ tag: 'expectedIntType', propertyId, message, actualType: 'float' }];
    } else if (typeof int !== 'number') {
      let message =
        propName + ' should be of type int but you provided type ' + typeof int + ' with value ' + JSON.stringify(int);
      return [{ tag: 'expectedIntType', propertyId, message, actualType: typeof int }];
    } else {
      return [];
    }
  },

  assertLong: function assertLong(propertyId: string, propName: string, long: number) {
    if (typeof long === 'number' && long !== Math.round(long)) {
      let message = propName + ' should be of type long but you provided type float with value ' + JSON.stringify(long);
      return [{ tag: 'expectedLongType', propertyId, message, actualType: 'float' }];
    } else if (typeof long !== 'number') {
      let message =
        propName +
        ' should be of type long but you provided type ' +
        typeof long +
        ' with value ' +
        JSON.stringify(long);
      return [{ tag: 'expectedLongType', propertyId, message, actualType: typeof long }];
    } else {
      return [];
    }
  },

  assertFloat: function assertFloat(propertyId: string, propName: string, float: number) {
    if (typeof float !== 'number') {
      let message =
        propName +
        ' should be of type float but you provided type ' +
        typeof float +
        ' with value ' +
        JSON.stringify(float);
      return [{ tag: 'expectedFloatType', propertyId, message, actualType: typeof float }];
    } else {
      return [];
    }
  },

  assertBool: function assertBool(propertyId: string, propName: string, bool: boolean) {
    if (typeof bool !== 'boolean') {
      let message =
        propName +
        ' should be of type boolean but you provided type ' +
        typeof bool +
        ' with value ' +
        JSON.stringify(bool);
      return [{ tag: 'expectedBoolType', propertyId, message, actualType: typeof bool }];
    } else {
      return [];
    }
  },

  assertMax: function assertMax(propertyId: string, propName: string, max: number, value: number) {
    if (value > max) {
      let message =
        propName + ' has a maximum value of ' + max + ' but you provided the value ' + JSON.stringify(value);
      return [{ tag: 'expectedMax', propertyId, message }];
    } else {
      return [];
    }
  },

  assertMin: function assertMin(propertyId: string, propName: string, min: number, value: number) {
    if (value < min) {
      let message =
        propName + ' has a minimum value of ' + min + ' but you provided the value ' + JSON.stringify(value);
      return [{ tag: 'expectedMin', propertyId, message }];
    } else {
      return [];
    }
  },

  assertList: function assertList(propertyId: string, propName: string, value: any) {
    if (!Array.isArray(value)) {
      let message = propName + ' should be of type list but you provided type ' + typeof value;
      return [{ tag: 'expectedList', propertyId, message }];
    } else {
      return [];
    }
  },

  assertNoAdditionalProperties: function assertNoAdditionalProperties(
    eventName: string,
    input: string[],
    spec: string[]
  ) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = 'Additional properties when sending event ' + eventName + ': ' + JSON.stringify(additionalKeys);
      return [{ tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message }];
    } else {
      return [];
    }
  },

  assertNoAdditionalUserProperties: function assertNoAdditionalProperties(
    eventName: string,
    input: string[],
    spec: string[]
  ) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message =
        'Additional user properties when sending event ' + eventName + ': ' + JSON.stringify(additionalKeys);
      return [{ tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message }];
    } else {
      return [];
    }
  },
};

let _avo_invoke: any;
let _avo_invoke_meta: any;
let _avo_sampling_rate = 1.0;
_avo_invoke = function _avo_invoke(
  env: AvoEnv,
  eventId: string,
  hash: string,
  messages: { tag: string; propertyId: string }[],
  origin: string
) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') {
    return;
  }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch('https://api.avo.app/i', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ac: 'dOWJrjHkMSgj5LGLaNA5',
          br: '-EjrmvJqg',
          en: env,
          ev: eventId,
          ha: hash,
          sc: '7JBjRhWGgpbREMm61E1Y',
          se: new Date().toISOString(),
          so: 'XGBEfLrXM',
          va: messages.length === 0,
          me: messages,
          or: origin,
        }),
      })
        .then(function (res: any) {
          return res.json();
        })
        .then(function (data: any) {
          _avo_sampling_rate = data.sa;
        })
        .catch(function () {});
    }
  }
};

_avo_invoke_meta = function _avo_invoke_meta(
  env: AvoEnv,
  type: string,
  messages: { tag: string; propertyId: string }[],
  origin: string
) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') {
    return;
  }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch('https://api.avo.app/i', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ac: 'dOWJrjHkMSgj5LGLaNA5',
          br: '-EjrmvJqg',
          en: env,
          ty: type,
          sc: '7JBjRhWGgpbREMm61E1Y',
          se: new Date().toISOString(),
          so: 'XGBEfLrXM',
          va: messages.length === 0,
          me: messages,
          or: origin,
        }),
      })
        .then(function (res: any) {
          return res.json();
        })
        .then(function (data: any) {
          _avo_sampling_rate = data.sa;
        })
        .catch(function () {});
    }
  }
};

let _avo_debugger_log: any;
let _avo_debugger_events_during_boot: any = [];
let _avo_debugger_ready = false;

if (typeof (window as any) !== 'undefined') {
  window.addEventListener('message', function (event) {
    if (event.origin !== 'https://www.avo.app') {
      return;
    }
    let iframe: any = document.getElementById('avo-debugger');
    if (iframe && event && event.data && event.data.type_ === 'avo-debugger-update-style') {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === 'avo-debugger-ready') {
      let message = {
        type_: 'avo-debugger-boot-events',
        schemaId: '7JBjRhWGgpbREMm61E1Y',
        href: window.location.href,
        events: _avo_debugger_events_during_boot,
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, 'https://www.avo.app/_debugger');
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(
  eventId: string,
  eventName: string,
  messages: any[],
  eventProperties: any[],
  userProperties: any[],
  groupProperties: any[]
) {
  if (typeof (window as any) === 'undefined') {
    return;
  }
  let event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties,
    userProperties,
    groupProperties,
  };

  if (_avo_debugger_ready) {
    let message = { type_: 'avo-debugger-events', events: [event] };
    (document.getElementById('avo-debugger') as any).contentWindow.postMessage(
      message,
      'https://www.avo.app/_debugger'
    );
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
};

function _avo_debugger_send_position(position: webDebuggerPosition) {
  if (typeof window === 'undefined') {
    return;
  }
  window.addEventListener('message', function (event) {
    if (event.origin !== 'https://www.avo.app') {
      return;
    }
    if (event.data.type_ === 'avo-debugger-ready' && position !== null) {
      var message = { type_: 'avo-debugger-position', position: position };
      document.getElementById('avo-debugger').contentWindow.postMessage(message, 'https://www.avo.app/_debugger');
    }
  });
}

export const OriginEcosystem = {
  BROWSER_BASED: 'Browser Based',
  JIRA: 'Jira',
  MS_TEAMS: 'MS teams',
  SLACK_2_0: 'Slack 2.0',
  ZENDESK: 'Zendesk',
} as const;
export type OriginEcosystemType = typeof OriginEcosystem;
export type OriginEcosystemValueType = OriginEcosystemType[keyof OriginEcosystemType];

export const OriginView = {
  APPROVALS: 'Approvals',
  BULK_ACTION_COMPLETE: 'Bulk Action Complete',
  BULK_ACTION_IN_PROGRESS: 'Bulk Action In Progress',
  CALENDAR: 'Calendar',
  CONFIGURATION: 'Configuration',
  CONFIGURATION_GENERAL: 'Configuration - General',
  CONFIGURATION_SHARING: 'Configuration - Sharing',
  COST_REPORT: 'CostReport',
  DAY: 'Day',
  DAY_VIEW: 'Day View',
  DAYS: 'Days',
  EXPENSES: 'Expenses',
  GLOBAL: 'Global',
  GLOBAL_BILLING_RATES: 'Global Billing Rates',
  GLOBAL_COST_RATES: 'Global Cost Rates',
  GRID: 'Grid',
  HUD_VIEW: 'Hud View',
  INBOX_VIEW: 'Inbox View',
  INTEGRATIONS: 'Integrations',
  ISSUE_PICKER_VIEW: 'Issue Picker View',
  ISSUE_PASSED_TIME_BY_RESOLUTION: 'IssuePassedTimeByResolution',
  ISSUE_PASSED_TIME_BY_STATUS: 'IssuePassedTimeByStatus',
  ISSUE_TIME: 'IssueTime',
  ISSUE_TIME_BALANCE: 'IssueTimeBalance',
  ISSUE_WORKED_TIME_BY_LABEL: 'IssueWorkedTimeByLabel',
  ISSUE_WORKED_TIME_BY_STATUS: 'IssueWorkedTimeByStatus',
  ISSUE_WORKED_TIME_BY_USER: 'IssueWorkedTimeByUser',
  LIST: 'List',
  MONTH: 'Month',
  MONTHS: 'Months',
  MY_WORK: 'My Work',
  OVERVIEW: 'Overview',
  PERMISSION_OVERVIEW: 'Permission Overview',
  PERSONAL: 'Personal',
  PIE_CHART: 'PieChart',
  PLAN_APPROVAL_OVERLAY: 'Plan Approval Overlay',
  QUARTERS: 'Quarters',
  SCOPE: 'Scope',
  SETTINGS_VIEW: 'Settings View',
  SUMMARY: 'Summary',
  TEAM: 'Team',
  TIME: 'Time',
  TIME_BALANCE: 'TimeBalance',
  TIME_ENTRY: 'TimeEntry',
  TIME_TRACKING: 'TimeTracking',
  TIMELINE: 'Timeline',
  TIMESHEET: 'Timesheet',
  TIMESPENT: 'Timespent',
  TRACKER_LIST_VIEW: 'Tracker List View',
  TRACKER_UPDATE_VIEW: 'Tracker Update View',
  WEEK: 'Week',
  WEEK_VIEW: 'Week View',
  WEEKS: 'Weeks',
  SELECT_MODE: 'Select Mode',
  SELECT_PROJECT: 'Select Project',
  DEFINE_LEVELS: 'Define Levels',
  NAMING: 'Naming',
  WELCOME_SCREEN: 'Welcome screen',
  FEEDBACK_MODE_STARTED: 'Feedback Mode Started',
  FEEDBACK_MODE_COMPLETED: 'Feedback Mode Completed',
  EMPTY_STATE: 'Empty State',
  ASSIGNMENTS: 'Assignments',
  PLAN_ITEMS: 'Plan Items',
  CHANGES: 'Changes',
  WORKING_TIMES: 'Working Times',
  CHANGES_BY_ME: 'Changes by me',
  PROJECT: 'Project',
  SCHEDULING: 'Scheduling',
  ATTACHED_FILES: 'Attached Files',
  EVERYONE_TIME_OFF: 'Everyone Time Off',
  PERSONAL_TIME_OFF: 'Personal Time Off',
  DELETED_ITEMS: 'Deleted Items',
  GROUPS: 'Groups',
  PROPERTIES: 'Properties',
  DASHBOARD_REPORTS: 'Dashboard & Reports',
  ROLES_RULES: 'Roles & Rules',
  NOTES: 'Notes',
  QA_NOTES: 'QA Notes',
  DEV_NOTES: 'Dev Notes',
  FILE: 'File',
  DISCUSSION: 'Discussion',
  SCHEDULE: 'Schedule',
  WORKLOAD: 'Workload',
  BOARD: 'Board',
  LAYOUT: 'Layout',
  SETTINGS_FILTER: 'Settings & Filter',
  GUEST_ACCESS: 'Guest Access',
  INVITE_GUEST: 'Invite Guest',
  PORTFOLIO: 'Portfolio',
  LIBRARY: 'Library',
  SEARCH: 'Search',
  WORKSPACE_ACCESS: 'Workspace Access',
  CONNECTIONS: 'Connections',
  LICENSED_USERS: 'Licensed Users',
  DASHBOARD_GUESTS: 'Dashboard Guests',
  DISCONNECTED_USERS: 'Disconnected Users',
  ADMINS: 'Admins',
  PENDING_INVITES: 'Pending Invites',
  ABOUT: 'About',
  AVAILABILITY: 'Availability',
  PROJECT_ACCESS: 'Project Access',
  GROUP_ACCESS: 'Group Access',
  ROLES_AND_RULES_ACCESS: 'Roles and Rules Access',
  PROFILE: 'Profile',
  SINGLE_PROJECT_VIEW: 'Single Project View',
  ADD_MULTIPLE_TASKS: 'Add Multiple Tasks',
  IMPORT_TASKS_FROM_EXCEL: 'Import Tasks from Excel',
  TASK_BOARD: 'Task Board',
  SETTINGS: 'Settings',
  CAPACITY: 'Capacity',
  SETTINGS_FILTERS: 'Settings & Filters',
  PROFILES: 'Profiles',
  GUEST_PROFILE: 'Guest Profile',
  ROLES_AND_RULES: 'Roles and Rules',
  MEMBERS: 'Members',
  ALLOCATION: 'Allocation',
  DISCOVER: 'Discover',
  SET_UP_TIMESHEETS: 'Set Up Timesheets',
  SET_UP_CI: 'Set Up CI',
} as const;
export type OriginViewType = typeof OriginView;
export type OriginViewValueType = OriginViewType[keyof OriginViewType];

export const OriginProduct = {
  BASE: 'Base',
  INTERACT_EVERYWHERE: 'Interact Everywhere',
  MOBILE: 'Mobile',
  TEMPO_CORE: 'Tempo Core',
  TEMPO_PROJECTS: 'Tempo Projects',
  TEMPO_TRACKER: 'Tempo Tracker',
  TIME_REPORTS: 'Time Reports',
  TIMESHEET_REPORTS_AND_GADGETS: 'Timesheet Reports and Gadgets',
  TIMESHEETS: 'Timesheets',
  STRUCTURE: 'Structure',
  STRUCTURE_GANTT: 'Structure Gantt',
  STRUCTURE_PI_PLANNER: 'Structure PI Planner',
  ROADMUNK: 'Roadmunk',
  CUSTOM_CHARTS_FOR_JIRA: 'Custom Charts for Jira',
  CUSTOM_CHARTS_FOR_CONFLUENCE: 'Custom Charts for Confluence',
  LIQUID_PLANNER: 'Liquid Planner',
  CAPACITY_INSIGHTS: 'Capacity Insights',
  FINANCIAL_MANAGER: 'Financial Manager',
  CAPACITY_PLANNER: 'Capacity Planner',
  PLANNER: 'Planner',
  COST_TRACKER: 'Cost Tracker',
  POWERBI_CONNECTOR: 'PowerBI Connector',
} as const;
export type OriginProductType = typeof OriginProduct;
export type OriginProductValueType = OriginProductType[keyof OriginProductType];

export const OriginPage = {
  API_INTEGRATION: 'API Integration',
  ACCESS_CONTROL: 'Access Control',
  ACCOUNT_OVERVIEW: 'Account Overview',
  ACCOUNT_TIMESHEET: 'Account Timesheet',
  ACCOUNTS_IMPORT_EXPORT: 'Accounts Import/Export',
  ACCOUNTS_OVERVIEW: 'Accounts Overview',
  ACTIVITY_FEED_CONFIGURATION: 'Activity Feed Configuration',
  BOARD_SIDEBAR: 'Board Sidebar',
  BULK_TIME_RECORDS_EDITOR: 'Bulk Time Records Editor',
  CALENDAR_INTEGRATION: 'Calendar Integration',
  CATEGORIES_OVERVIEW: 'Categories Overview',
  CONSOLIDATED_REPORT: 'Consolidated Report',
  CUSTOM_FIELD_EXPORT_CONFIGURATION: 'Custom Field Export Configuration',
  CUSTOMER_OVERVIEW: 'Customer Overview',
  CUSTOMER_REPORT: 'Customer Report',
  CUSTOMERS_OVERVIEW: 'Customers Overview',
  DASHBOARD: 'Dashboard',
  DATA_RETENTION: 'Data Retention',
  GADGET: 'Gadget',
  GLOBAL_SETTINGS: 'Global Settings',
  HOLIDAY_SCHEME_CONFIGURATION: 'Holiday Scheme Configuration',
  HOLIDAY_SCHEME_MEMBERS: 'Holiday Scheme Members',
  HOLIDAY_SCHEMES: 'Holiday Schemes',
  HOME: 'Home',
  IDENTITY_SERVICE: 'Identity Service',
  INSTALLATION_FAILURE_PAGE: 'Installation Failure Page',
  INSTALLATION_PAGE: 'Installation Page',
  INSTALLATION_SUCCESS_PAGE: 'Installation Success Page',
  INTERNAL_ISSUES: 'Internal Issues',
  ISSUE_HISTORY: 'Issue History',
  JIRA_MOBILE_APP: 'JIRA Mobile App',
  JIRA_ISSUE_SEARCH: 'Jira Issue Search',
  JIRA_ISSUE_VIEW: 'Jira Issue View',
  LEGACY_TEAM_CAPACITY_REPORT: 'Legacy Team Capacity Report',
  LOGGED_TIME_REPORT: 'Logged Time Report',
  LOGGING_WORK_CONFIGURATION: 'Logging Work Configuration',
  MESSAGES: 'Messages',
  MISSING_INFORMATION: 'Missing Information',
  MY_WORK: 'My Work',
  PERIOD_CONFIGURATION: 'Period Configuration',
  PERIOD_MANAGEMENT: 'Period Management',
  PERMISSION_OVERVIEW: 'Permission Overview',
  PERMISSION_ROLES: 'Permission Roles',
  PLANNED_TIME_REPORT: 'Planned Time Report',
  PLANNED_VS_ACTUAL_REPORT: 'Planned vs Actual Report',
  PLANNING_TIME_CONFIGURATION: 'Planning Time Configuration',
  PRINT_REPORT: 'Print Report',
  PROGRAMS_OVERVIEW: 'Programs Overview',
  PROJECT_EXPENSES: 'Project Expenses',
  PROJECT_LIST: 'Project List',
  PROJECT_OVERVIEW: 'Project Overview',
  PROJECT_SCOPE: 'Project Scope',
  PROJECT_TEAM: 'Project Team',
  PROJECT_TIME_APPROVAL: 'Project Time Approval',
  PROJECT_WELCOME: 'Project Welcome',
  REPORT: 'Report',
  REPORT_PAGE: 'Report Page',
  REPORTS_OVERVIEW: 'Reports Overview',
  RESOURCE_PLANNING: 'Resource Planning',
  SCHEDULER_CONFIGURATION: 'Scheduler Configuration',
  SETTINGS: 'Settings',
  STANDALONE_WORKLOG_DIALOG: 'Standalone Worklog Dialog',
  TASK_VIEW: 'Task View',
  TEAM_APPROVALS: 'Team Approvals',
  TEAM_OVERVIEW: 'Team Overview',
  TEAM_PERMISSIONS: 'Team Permissions',
  TEAM_PLANNING: 'Team Planning',
  TEAM_ROLES: 'Team Roles',
  TEAM_TIMESHEET: 'Team Timesheet',
  TEAMS_OVERVIEW: 'Teams Overview',
  TEMPO_ACCOUNTS: 'Tempo Accounts',
  TEMPO_APPS: 'Tempo Apps',
  TEMPO_MY_WORK: 'Tempo My Work',
  TEMPO_PROJECT_CONFIG: 'Tempo Project Config',
  TEMPO_REPORTS: 'Tempo Reports',
  TEMPO_TEAMS: 'Tempo Teams',
  TRACKER: 'Tracker',
  UI_PREFERENCES: 'UI Preferences',
  USER_APPROVAL_LOG: 'User Approval Log',
  USER_APPROVAL_TIMESHEET: 'User Approval Timesheet',
  USER_TIMESHEET: 'User Timesheet',
  WORK_ATTRIBUTES: 'Work Attributes',
  WORKLOAD_SCHEME_MEMBERS: 'Workload Scheme Members',
  WORKLOAD_SCHEMES: 'Workload Schemes',
  WORKLOG_CALENDAR: 'Worklog Calendar',
  CONFLUENCE_MACRO: 'Confluence macro',
  JIRA_DASHBOARD_ITEM: 'Jira dashboard item',
  CONFLUENCE_MACRO_EDIT: 'Confluence macro edit',
  EXTENSION_GANTT_DASHBOARD: 'Extension gantt dashboard',
  EXTENSION_BOARDS: 'Extension boards',
  BOARD: 'Board',
  PROJECT_BOARD: 'Project board',
  MANAGE: 'Manage',
  TEMPO_ERROR: 'Tempo Error',
  ADMINISTRATION: 'Administration',
  WORKLOG_EDITOR_BULK_ACTIONS: 'Worklog Editor Bulk Actions',
  HISTORY_BULK_ACTIONS: 'History Bulk Actions',
  PROJECT_ATTRIBUTES: 'Project Attributes',
  MANAGE_STAFF: 'Manage Staff',
  SKILLS: 'Skills',
  PORTFOLIO_TIMELINE: 'Portfolio Timeline',
  PORTFOLIO_LIST: 'Portfolio List',
  EXTERNAL_PAGE: 'External Page',
  PORTFOLIO_SUMMARY: 'Portfolio Summary',
  TEAM_CAPACITY: 'Team Capacity',
  PROJECT_CONFIGURATION_GENERAL: 'Project Configuration General',
  PROJECT_CONFIGURATION_MEMBERS_AND_ROLES: 'Project Configuration Members and Roles',
  PROJECT_CONFIGURATION_PROJECT_ATTRIBUTES: 'Project Configuration Project Attributes',
  PROJECT_CONFIGURATION_SHARING: 'Project Configuration Sharing',
  PROJECT_CONFIGURATION_COST_RATES: 'Project Configuration Cost Rates',
  PROJECT_CONFIGURATION_BILLING_RATES: 'Project Configuration Billing Rates',
  GLOBAL_SETTINGS_COST_RATES: 'Global Settings Cost Rates',
  GLOBAL_SETTINGS_BILLING_RATES: 'Global Settings Billing Rates',
  PROJECT_APPROVALS_TIMESHEET: 'Project Approvals Timesheet',
  PROJECT_APPROVALS_OVERVIEW: 'Project Approvals Overview',
  PORTFOLIO_CONFIGURATION_GENERAL: 'Portfolio Configuration General',
  PORTFOLIO_CONFIGURATION_SHARING: 'Portfolio Configuration Sharing',
  GLOBAL_CONFIGURATION_COST_RATE: 'Global Configuration Cost Rate',
  GLOBAL_CONFIGURATION_BILLING_RATE: 'Global Configuration Billing Rate',
  PROJECT_CONFIGURATION: 'Project Configuration',
  PROJECT_TIMESHEET: 'Project Timesheet',
  TEAMS_CLASSIFICATION: 'Teams Classification',
  GRID: 'Grid',
  PORTFOLIO: 'Portfolio',
  EDIT_PANEL: 'Edit Panel',
  DASHBOARDS: 'Dashboards',
  REVIEW_ALL_WORKLOGS: 'Review All Worklogs',
  TIME_TRACKING_MODE: 'Time Tracking Mode',
  REVIEW_TIME: 'Review Time',
  REPORT_LIST: 'Report List',
  ACADEMY: 'Academy',
  PEOPLE: 'People',
  LIBRARY: 'Library',
  MY_DISCUSSIONS: 'My Discussions',
  MY_FAVORITES: 'My Favorites',
  WORKLOAD: 'Workload',
  SEARCH: 'Search',
  CHANGES: 'Changes',
  PROFILES: 'Profiles',
  GROUP: 'Group',
  AVAILABILITY: 'Availability',
  TIMESHEETS: 'Timesheets',
  MY_WORK_AVAILABILITY: 'My Work Availability',
  MY_WORK_SEARCH: 'My Work Search',
  MY_WORK_CHANGES: 'My Work Changes',
  ACCESS: 'Access',
  PROFILE: 'Profile',
  PACKAGE: 'Package',
  LOGIN: 'Login',
  SSO_LOGIN: 'SSO Login',
  LOGOUT: 'Logout',
  TRIAL_SIGN_UP: 'Trial Sign Up',
  FORGOT_PASSWORD: 'Forgot Password',
  SET_PASSWORD: 'Set Password',
  RESET_PASSWORD: 'Reset Password',
  ACCEPT_INVITATION: 'Accept Invitation',
  EMAIL_CONFIRMATION: 'Email Confirmation',
  EXPIRED_EMAIL_CONFIRMATION: 'Expired Email Confirmation',
  DISCONNECTED_USER_LOGIN: 'Disconnected User Login',
  DISCONNECTED_USER_RESTART_ACCOUNT: 'Disconnected User - Restart Account',
  PORTABLE_MANAGE_ACCOUNT: 'Portable Manage Account',
  PORTABLE_ACADEMY: 'Portable Academy',
  GUEST_PASSPORTS: 'Guest Passports',
  ACADEMY_HOME: 'Academy Home',
  ACADEMY_TRACK: 'Academy - Track',
  PORTABLE_COURSE: 'Portable Course',
  PORTABLE_ITEM: 'Portable Item',
  PORTABLE_ACADEMY_LESSON: 'Portable Academy Lesson',
  PORTABLE_DASHBOARD: 'Portable Dashboard',
  PORTABLE_REPORT: 'Portable Report',
  PORTABLE_LIBRARY: 'Portable Library',
  PORTABLE_ITEM_LIBRARY: 'Portable Item Library',
  PORTABLE_LIBRARY_ITEM_DASHBOARD: 'Portable Library Item Dashboard',
  FAVORITES: 'Favorites',
  DASHBOARD_CREATE: 'Dashboard - Create',
  DASHBOARD_GUEST_ACCESS: 'Dashboard - Guest Access',
  DASHBOARD_GUEST_ACCESS_WIDGET_CLICK_THROUGH_VIEW: 'Dashboard - Guest Access - Widget Click-through View',
  WORKSPACE_LIBRARY: 'Workspace -  Library',
  WORKSPACE_DASHBOARD: 'Workspace - Dashboard',
  WORKSPACE_DASHBOARD_DESIGN: 'Workspace - Dashboard - Design',
  N_A: 'N/A',
  WORKSPACE_DASHBOARD_CHANGES_WIDGET_CLICK_THROUGH: 'Workspace - Dashboard - Changes Widget Click-through',
  WORKSPACE_DASHBOARD_LIST_WIDGET_CLICK_THROUGH: 'Workspace - Dashboard - List Widget Click-through',
  WORKSPACE_DASHBOARD_METRICS_WIDGET_CLICK_THROUGH: 'Workspace - Dashboard - Metrics Widget Click-through',
  WORKSPACE_DASHBOARD_DASHBOARD_NOTE_WIDGET_CLICK_THROUGH:
    'Workspace - Dashboard - Dashboard Note Widget Click-through',
  WORKSPACE_DASHBOARD_INTAKE_WIDGET_CLICK_THROUGH: 'Workspace - Dashboard - Intake Widget Click-through',
  WORKSPACE_DASHBOARD_INTAKE_WIDGET_SETTINGS: 'Workspace - Dashboard - Intake Widget Settings',
  WORKSPACE_DASHBOARD_WORKLOAD_WIDGET_CLICK_THROUGH: 'Workspace - Dashboard - Workload Widget Click-through',
  WORKSPACE_DASHBOARD_TASK_BOARD_WIDGET_CLICK_THROUGH: 'Workspace - Dashboard - Task Board Widget Click-through',
  WORKSPACE_REPORT: 'Workspace - Report',
  WORKSPACE_REPORT_DESIGN: 'Workspace - Report - Design',
  PROJECT_DASHBOARD_CHANGES_WIDGET_CLICK_THROUGH: 'Project - Dashboard - Changes Widget Click-through',
  PROJECT_DASHBOARD_LIST_WIDGET_CLICK_THROUGH: 'Project - Dashboard - List Widget Click-through',
  PROJECT_DASHBOARD_METRICS_WIDGET_CLICK_THROUGH: 'Project - Dashboard - Metrics Widget Click-through',
  PROJECT_DASHBOARD_DASHBOARD_NOTE_WIDGET_CLICK_THROUGH: 'Project - Dashboard - Dashboard Note Widget Click-through',
  PROJECT_DASHBOARD_INTAKE_WIDGET_CLICK_THROUGH: 'Project - Dashboard - Intake Widget Click-through',
  PROJECT_DASHBOARD_INTAKE_WIDGET_SETTINGS: 'Project - Dashboard - Intake Widget Settings',
  PROJECT_DASHBOARD_WORKLOAD_WIDGET_CLICK_THROUGH: 'Project - Dashboard - Workload Widget Click-through',
  PROJECT_DASHBOARD_TASK_BOARD_WIDGET_CLICK_THROUGH: 'Project - Dashboard - Task Board Widget Click-through',
  SCHEDULED: 'Scheduled',
  SCHEDULED_REPORT: 'Scheduled - Report',
  SCHEDULED_REPORT_DESIGN: 'Scheduled - Report - Design',
  SCHEDULED_DASHBOARD: 'Scheduled - Dashboard',
  SCHEDULED_DASHBOARD_DESIGN: 'Scheduled - Dashboard - Design',
  SCHEDULED_DASHBOARD_TASK_BOARD_WIDGET_CLICK_THROUGH: 'Scheduled - Dashboard - Task Board Widget Click-through',
  SCHEDULED_DASHBOARD_CHANGES_WIDGET_CLICK_THROUGH: 'Scheduled - Dashboard - Changes Widget Click-through',
  SCHEDULED_DASHBOARD_WORKLOAD_WIDGET_CLICK_THROUGH: 'Scheduled - Dashboard - Workload Widget Click-through',
  SCHEDULED_DASHBOARD_METRIC_WIDGET_CLICK_THROUGH: 'Scheduled - Dashboard - Metric Widget Click-through',
  SCHEDULED_DASHBOARD_DASHBOARD_NOTE_WIDGET_CLICK_THROUGH:
    'Scheduled - Dashboard - Dashboard Note Widget Click-through',
  SCHEDULED_DASHBOARD_LIST_WIDGET_CLICK_THROUGH: 'Scheduled - Dashboard - List Widget Click-through',
  SCHEDULED_DASHBOARD_INTAKE_WIDGET_CLICK_THROUGH: 'Scheduled - Dashboard - Intake Widget Click-through',
  SCHEDULED_DASHBOARD_INTAKE_WIDGET_SETTINGS: 'Scheduled - Dashboard - Intake Widget Settings',
  SCHEDULED_CHANGES: 'Scheduled - Changes',
  SCHEDULED_GRID: 'Scheduled - Grid',
  SCHEDULED_SEARCH: 'Scheduled - Search',
  SCHEDULED_WORKLOAD: 'Scheduled - Workload',
  PENDING: 'Pending',
  PENDING_REPORT: 'Pending - Report',
  PENDING_REPORT_DESIGN: 'Pending - Report - Design',
  PENDING_REPORT_DESIGN_SETTINGS: 'Pending - Report - Design - Settings',
  PENDING_DASHBOARD: 'Pending - Dashboard',
  CAPACITY: 'Capacity',
  CAPACITY_ALLOCATION: 'Capacity - Allocation',
  PENDING_DASHBOARD_DESIGN: 'Pending - Dashboard - Design',
  PENDING_DASHBOARD_DESIGN_GUEST_ACCESS_INVITE: 'Pending - Dashboard - Design - Guest Access - Invite',
  PENDING_DASHBOARD_TASK_BOARD_WIDGET_CLICK_THROUGH: 'Pending - Dashboard - Task Board Widget Click-through',
  PENDING_DASHBOARD_CHANGES_WIDGET_CLICK_THROUGH: 'Pending - Dashboard - Changes Widget Click-through',
  PENDING_DASHBOARD_WORKLOAD_WIDGET_CLICK_THROUGH: 'Pending - Dashboard - Workload Widget Click-through',
  PENDING_DASHBOARD_METRICS_WIDGET_CLICK_THROUGH: 'Pending - Dashboard - Metrics Widget Click-through',
  PENDING_DASHBOARD_DASHBOARD_NOTE_WIDGET_CLICK_THROUGH: 'Pending - Dashboard - Dashboard Note Widget Click-through',
  PENDING_DASHBOARD_LIST_GRID_WIDGET_CLICK_THROUGH: 'Pending - Dashboard - List Grid Widget Click-through',
  PENDING_DASHBOARD_INTAKE_WIDGET_CLICK_THROUGH: 'Pending - Dashboard - Intake Widget Click-through',
  PENDING_DASHBOARD_INTAKE_WIDGET_SETTINGS: 'Pending - Dashboard - Intake Widget Settings',
  PENDING_CHANGES: 'Pending - Changes',
  PENDING_GRID: 'Pending - Grid',
  PENDING_SEARCH: 'Pending - Search',
  ARCHIVED: 'Archived',
  ARCHIVED_REPORT: 'Archived - Report',
  ARCHIVED_REPORT_DESIGN: 'Archived - Report - Design',
  ARCHIVED_DASHBOARD: 'Archived - Dashboard',
  ARCHIVED_DASHBOARD_DESIGN: 'Archived - Dashboard - Design',
  ARCHIVED_DASHBOARD_DESIGN_GUEST_ACCESS_INVITE: 'Archived - Dashboard - Design - Guest Access - Invite',
  ARCHIVED_DASHBOARD_TASK_BOARD_WIDGET_CLICK_THROUGH: 'Archived - Dashboard - Task Board Widget Click-through',
  ARCHIVED_DASHBOARD_CHANGES_WIDGET_CLICK_THROUGH: 'Archived - Dashboard - Changes Widget Click-through',
  ARCHIVED_DASHBOARD_WORKLOAD_WIDGET_CLICK_THROUGH: 'Archived - Dashboard - Workload Widget Click-through',
  ARCHIVED_DASHBOARD_METRICS_WIDGET_CLICK_THROUGH: 'Archived - Dashboard - Metrics Widget Click-through',
  ARCHIVED_DASHBOARD_NOTE_WIDGET_CLICK_THROUGH: 'Archived - Dashboard - Note Widget Click-through',
  ARCHIVED_DASHBOARD_LIST_GRID_WIDGET_CLICK_THROUGH: 'Archived - Dashboard - List Grid Widget Click-through',
  ARCHIVED_DASHBOARD_INTAKE_WIDGET_CLICK_THROUGH: 'Archived - Dashboard - Intake Widget Click-through',
  ARCHIVED_DASHBOARD_INTAKE_SETTINGS: 'Archived - Dashboard - Intake - Settings',
  TEMPLATE: 'Template',
  WORKSPACE: 'Workspace',
  WORKSPACE_COST_CODES: 'Workspace - Cost Codes',
  WORKSPACE_CUSTOM_DATA: 'Workspace - Custom Data',
  WORKSPACE_CUSTOMIZE_STATUS: 'Workspace - Customize Status',
  WORKSPACE_RATE_SHEET_TABLE: 'Workspace - Rate Sheet Table',
  WORKSPACE_SETTINGS: 'Workspace - Settings',
  INTEGRATIONS: 'Integrations',
  SLACK_INTEGRATION: 'Slack Integration',
  JIRA_INTEGRATION: 'Jira Integration',
  SINGLE_SIGN_ON: 'Single Sign On',
  SINGLE_SIGN_ON_SETUP: 'Single Sign On - Setup',
  SINGLE_SIGN_ON_MANAGE_SSO: 'Single Sign On - Manage SSO',
  API: 'API',
  ALL_USERS: 'All Users',
  ALL_USERS_INVITE: 'All Users - Invite',
  ORGANIZATION_USER_PROFILE: 'Organization User Profile',
  ORGANIZATION_HUB_USERS_INTEGRATIONS: 'Organization Hub - Users - Integrations',
  ORGANIZATION_HUB_USERS_INTEGRATIONS_SLACK_ADMIN: 'Organization Hub - Users - Integrations - Slack - Admin',
  UPGRADE: 'Upgrade',
  MANAGE_ACCOUNT: 'Manage Account',
  MANAGE_ACCOUNT_UPGRADE: 'Manage Account - Upgrade',
  MANAGE_ACCOUNT_DOWNGRADE: 'Manage Account - Downgrade',
  ALL_PROJECTS: 'All Projects',
  ALL_PROJECTS_SEARCH: 'All Projects - Search',
  PROJECT: 'Project',
  PROJECT_LIBRARY: 'Project - Library',
  PROJECT_REPORT: 'Project - Report',
  PROJECT_REPORT_DESIGN: 'Project - Report - Design',
  PROJECT_ADD_TASKS: 'Project - Add Tasks',
  PROJECT_DASHBOARD_DESIGN: 'Project - Dashboard - Design',
  PROJECT_DASHBOARD_DESIGN_INVITE_GUEST: 'Project - Dashboard - Design - Invite Guest',
  PROJECT_DASHBOARD: 'Project - Dashboard',
  PACKAGE_REPORT_DESIGN: 'Package - Report - Design',
  PACKAGE_DASHBOARD: 'Package - Dashboard',
  PACKAGE_DASHBOARD_DESIGN: 'Package - Dashboard - Design',
  PACKAGE_DASHBOARD_DESIGN_INVITE_GUEST: 'Package - Dashboard - Design - Invite Guest',
  PACKAGE_CAPACITY: 'Package - Capacity',
  PACKAGE_DASHBOARD_CHANGES_WIDGET_CLICK_THROUGH: 'Package - Dashboard - Changes Widget Click-through',
  PACKAGE_DASHBOARD_GRID_WIDGET_CLICK_THROUGH: 'Package - Dashboard - Grid Widget Click-through',
  PACKAGE_DASHBOARD_METRICS_WIDGET_CLICK_THROUGH: 'Package - Dashboard - Metrics Widget Click-through',
  PACKAGE_DASHBOARD_DASHBOARD_NOTE_WIDGET_CLIC_THROUGH: 'Package - Dashboard - Dashboard Note Widget Clic-through',
  PACKAGE_DASHBOARD_INTAKE_WIDGET_CLICK_THROUGH: 'Package - Dashboard - Intake Widget Click-through',
  PACKAGE_DASHBOARD_INTAKE_WIDGET_SETTINGS: 'Package - Dashboard - Intake Widget Settings',
  ADD_MEMBER: 'Add Member',
  PEOPLE_MEMBER_PROFILE: 'People - Member Profile',
  PEOPLE_MEMBER_PROFILE_TASK_BOARD: 'People - Member Profile - Task Board',
  PEOPLE_MEMBER_PROFILE_WORKLOAD: 'People - Member Profile - Workload',
  PEOPLE_ADD_PLACEHOLDER: 'People - Add Placeholder',
  PEOPLE_PLACEHOLDER_PROFILE: 'People - Placeholder Profile',
  PEOPLE_ADD_RESOURCE: 'People - Add Resource',
  PEOPLE_RESOURCE_PROFILE: 'People - Resource Profile',
  GUEST_PROFILE: 'Guest Profile',
  PEOPLE_GROUP: 'People - Group',
  GROUP_ROLES_AND_RULES_ACCESS: 'Group - Roles and Rules Access',
  WORKSPACE_BILLING_RATE_SHEETS_RULES: 'Workspace - Billing Rate Sheets - Rules',
  WORKSPACE_BILLING_RATE_SHEETS_PROJECTS_USING: 'Workspace - Billing Rate Sheets - Projects Using',
  WORKSPACE_PAY_RATE_SHEETS_RULES: 'Workspace - Pay Rate Sheets - Rules',
  WORKSPACE_PAY_RATE_SHEETS_PROJECTS_USING: 'Workspace - Pay Rate Sheets - Projects Using',
  CAPACITY_INSIGHTS_OPERATION_DASHBOARD: 'Capacity Insights Operation Dashboard',
  STRUCTURE_EMPTY_STATE: 'Structure Empty State',
  MY_WORK_APPROVALS: 'My Work Approvals',
  COLLECTIONS: 'Collections',
  BITBUCKET_INTEGRATION: 'Bitbucket Integration',
} as const;
export type OriginPageType = typeof OriginPage;
export type OriginPageValueType = OriginPageType[keyof OriginPageType];

export const EstimateType = {
  TIME_TRACKING: 'Time tracking',
  STORY_POINT: 'Story point',
  COUNT: 'Count',
  TIME_REMAINING: 'Time Remaining',
} as const;
export type EstimateTypeType = typeof EstimateType;
export type EstimateTypeValueType = EstimateTypeType[keyof EstimateTypeType];

export const OriginAction = {
  ADD_TEAM_MEMBER: 'Add Team Member',
  ADDED_EFFECTIVE_DATE_RATE: 'Added Effective Date Rate',
  ANYWHERE: 'Anywhere',
  AUTOMATIC_UPDATE: 'Automatic Update',
  BULK_DELETE: 'Bulk Delete',
  BULK_EDIT: 'Bulk Edit',
  BULK_MOVE: 'Bulk Move',
  CHANGED_PRINT_REPORT_TO_LANDSCAPE: 'Changed Print Report to Landscape',
  CHANGED_PRINT_REPORT_TO_PORTRAIT: 'Changed Print Report to Portrait',
  CHANGED_REPORT_DATE_DISPLAY_TO_DAYS: 'Changed Report Date Display to Days',
  CHANGED_REPORT_DATE_DISPLAY_TO_MONTHS: 'Changed Report Date Display to Months',
  CHANGED_REPORT_DATE_DISPLAY_TO_QUARTERS: 'Changed Report Date Display to Quarters',
  CHANGED_REPORT_DATE_DISPLAY_TO_WEEKS: 'Changed Report Date Display to Weeks',
  CHANGED_TEAM: 'Changed Team',
  CHANGED_TIME_UNIT: 'Changed Time Unit',
  CLICK_PROVIDE_FEEDBACK: 'Click Provide Feedback',
  CLICKED_ALLOW: 'Clicked Allow',
  CLICKED_APPLY_ON_PERIOD_NAVIGATION: 'Clicked Apply on Period Navigation',
  CLICKED_APPROVE_BUTTON: 'Clicked Approve Button',
  CLICKED_BULK_TIME_RECORDS_EDITOR: 'Clicked Bulk Time Records Editor',
  CLICKED_CANCEL: 'Clicked Cancel',
  CLICKED_CARD_ON_CANVAS: 'Clicked Card on Canvas',
  CLICKED_CONNECT: 'Clicked Connect',
  CLICKED_CONTACT_SALES: 'Clicked Contact Sales',
  CLICKED_CONTACT_US: 'Clicked Contact Us',
  CLICKED_DELETE_BUTTON: 'Clicked Delete Button',
  CLICKED_DONT_ALLOW: "Clicked Don't Allow",
  CLICKED_HELP_CENTER: 'Clicked Help Center',
  CLICKED_HORIZONTAL_COLLAPSE: 'Clicked Horizontal Collapse',
  CLICKED_HORIZONTAL_EXPAND: 'Clicked Horizontal Expand',
  CLICKED_LEARN_MORE: 'Clicked Learn More',
  CLICKED_LOG_WORK_BUTTON: 'Clicked Log Work Button',
  CLICKED_MY_WORK_IN_NAVIGATION_MENU: 'Clicked My Work in Navigation Menu',
  CLICKED_NEW_IMPORTANT_INFO: 'Clicked New & Important Info',
  CLICKED_OPEN_STRUCTURE: 'Clicked Open Structure',
  CLICKED_PAUSE_BUTTON: 'Clicked Pause Button',
  CLICKED_PLAN_TIME_BUTTON: 'Clicked Plan Time Button',
  CLICKED_PLUS_BUTTON: 'Clicked Plus Button',
  CLICKED_PROJECT_APPROVAL_TAB: 'Clicked Project Approval Tab',
  CLICKED_REJECT_BUTTON: 'Clicked Reject Button',
  CLICKED_REPORTS_IN_NAVIGATION_MENU: 'Clicked Reports in Navigation Menu',
  CLICKED_RESUME_BUTTON: 'Clicked Resume Button',
  CLICKED_RETURN_TO_START_BUTTON: 'Clicked Return To Start Button',
  CLICKED_TECHNICAL_SUPPORT: 'Clicked Technical Support',
  CLICKED_TEMPO_STATUS: 'Clicked Tempo Status',
  CLICKED_VERTICAL_COLLAPSE: 'Clicked Vertical Collapse',
  CLICKED_VERTICAL_EXPAND: 'Clicked Vertical Expand',
  CLICKED_X_ON_FORM: 'Clicked X on Form',
  CLICKED_IN_CELL: 'Clicked in Cell',
  CLICKED_IN_TASK_PANEL: 'Clicked in Task Panel',
  CLICKED_ON_ADD_EFFECTIVE_DATE_RATE: 'Clicked on Add Effective Date Rate',
  CLICKED_ON_ADD_PROJECT: 'Clicked on Add Project',
  CLICKED_ON_ADD_TEAMS_BUTTON: 'Clicked on Add Teams Button',
  CLICKED_ON_ARROW: 'Clicked on Arrow',
  CLICKED_ON_BACK_ARROW: 'Clicked on Back Arrow',
  CLICKED_ON_CANCEL_BUTTON: 'Clicked on Cancel Button',
  CLICKED_ON_CANVAS: 'Clicked on Canvas',
  CLICKED_ON_CANVAS_HEADER_BUTTON: 'Clicked on Canvas Header Button',
  CLICKED_ON_CAPEX_LINK: 'Clicked on Capex Link',
  CLICKED_ON_CELL: 'Clicked on Cell',
  CLICKED_ON_CLOSE_BUTTON: 'Clicked on Close Button',
  CLICKED_ON_CONFIRM: 'Clicked on Confirm',
  CLICKED_ON_CREATE_BUTTON: 'Clicked on Create Button',
  CLICKED_ON_CURRENT_LINK: 'Clicked on Current Link',
  CLICKED_ON_DATE: 'Clicked on Date',
  CLICKED_ON_DAYS_BUTTON: 'Clicked on Days Button',
  CLICKED_ON_DELETE_PORTFOLIO: 'Clicked on Delete Portfolio',
  CLICKED_ON_DROPDOWN: 'Clicked on Dropdown',
  CLICKED_ON_EXPANDED_CELL: 'Clicked on Expanded Cell',
  CLICKED_ON_EXPORT_BUTTON: 'Clicked on Export Button',
  CLICKED_ON_FILTER_PICKER: 'Clicked on Filter Picker',
  CLICKED_ON_FORWARD_ARROW: 'Clicked on Forward Arrow',
  CLICKED_ON_FULL_DESCRIPTION_LINK: 'Clicked on Full Description Link',
  CLICKED_ON_GAP_SUGGESTION: 'Clicked on Gap Suggestion',
  CLICKED_ON_GRACE_GRANTED_ITEM: 'Clicked on Grace Granted Item',
  CLICKED_ON_GROUPING_FILTER: 'Clicked on Grouping Filter',
  CLICKED_ON_HELP: 'Clicked on Help',
  CLICKED_ON_INCLUDE_COMPLETED_PROJECT: 'Clicked on Include Completed Project',
  CLICKED_ON_ISSUE_ACTIONS: 'Clicked on Issue Actions',
  CLICKED_ON_LAST_LINK: 'Clicked on Last Link',
  CLICKED_ON_LOG_TIME_BUTTON: 'Clicked on Log Time button',
  CLICKED_ON_LOG_WORK_LINK_FROM_DROPDOWN: 'Clicked on Log Work Link from Dropdown',
  CLICKED_ON_MOVE_LINK: 'Clicked on Move Link',
  CLICKED_ON_NOTIFICATION_LINK: 'Clicked on Notification Link',
  CLICKED_ON_OPEX_LINK: 'Clicked on Opex Link',
  CLICKED_ON_PLAN: 'Clicked on Plan',
  CLICKED_ON_PORTFOLIO_LINK_PROJECT: 'Clicked on Portfolio Link - Project',
  CLICKED_ON_PORTFOLIO_TILE: 'Clicked on Portfolio Tile',
  CLICKED_ON_PRINT_BUTTON_IN_LANDSCAPE_MODE: 'Clicked on Print Button in Landscape mode',
  CLICKED_ON_PRINT_BUTTON_IN_PORTRAIT_MODE: 'Clicked on Print Button in Portrait mode',
  CLICKED_ON_PROJECT_STATUS: 'Clicked on Project Status',
  CLICKED_ON_QUICK_ACTION: 'Clicked on Quick Action',
  CLICKED_ON_RE_IMPORT_SCOPE: 'Clicked on Re-Import Scope',
  CLICKED_ON_REMOVE_PROJECT: 'Clicked on Remove Project',
  CLICKED_ON_REMOVE_TEAMS_BUTTON: 'Clicked on Remove Teams Button',
  CLICKED_ON_SHARE_BUTTON: 'Clicked on Share Button',
  CLICKED_ON_SUBMIT_BUTTON: 'Clicked on Submit Button',
  CLICKED_ON_SUBSCRIBE: 'Clicked on Subscribe',
  CLICKED_ON_TEAM_MEMBER_LINK: 'Clicked on Team Member Link',
  CLICKED_ON_TIME_AXIS: 'Clicked on Time Axis',
  CLICKED_ON_TIMERECORD: 'Clicked on TimeRecord',
  CLICKED_ON_TIMESHEET: 'Clicked on TimeSheet',
  CLICKED_ON_TIMESHEET_DUE: 'Clicked on Timesheet Due',
  CLICKED_ON_TODAY_BUTTON: 'Clicked on Today Button',
  CLICKED_ON_TODAY_LINK: 'Clicked on Today Link',
  CLICKED_ON_VIEW_REPORT: 'Clicked on View Report',
  CLICKED_ON_WEEK_HEADER: 'Clicked on Week Header',
  CLICKED_ON_WEEKS_BUTTON: 'Clicked on Weeks Button',
  COMMAND_LINE: 'Command Line',
  CONFIGURE: 'Configure',
  CONVERTED_GOOGLE_CAL_EVENT: 'Converted Google Cal Event',
  CONVERTED_OFFICE_365_CAL_EVENT: 'Converted Office 365 Cal Event',
  CONVERTED_PLAN: 'Converted Plan',
  CONVERTED_SUGGESTION: 'Converted Suggestion',
  CONVERTED_TRACKER: 'Converted Tracker',
  CONVERTED_FROM_SUGGESTION: 'Converted from Suggestion',
  COPIED_PLAN: 'Copied Plan',
  COPIED_TIME_RECORD: 'Copied Time Record',
  COPIED_WORKLOG: 'Copied Worklog',
  CREATED_NEW_TRACKER: 'Created New Tracker',
  CREATED_TAG: 'Created Tag',
  CREATED_FROM_TRACKER: 'Created from Tracker',
  DELETE: 'Delete',
  DELETE_ENTIRE_PLAN: 'Delete Entire Plan',
  DELETE_ENTIRE_RECURRING_PLAN_SERIES: 'Delete Entire Recurring Plan Series',
  DELETE_PARTIAL_PLAN: 'Delete Partial Plan',
  DELETE_BY_SWIPING: 'Delete by Swiping',
  DELETE_THIS_AND_UPCOMING_FROM_REPEATED_PLAN_SERIES: 'Delete this and upcoming from repeated plan series',
  DELETED_EFFECTIVE_DATE_RATE: 'Deleted Effective Date Rate',
  DELETED_TAG: 'Deleted Tag',
  DRAGGED_PLAN_AROUND_CANVAS: 'Dragged Plan around Canvas',
  DRAGGED_SUGGESTION_ON_CANVAS: 'Dragged Suggestion on Canvas',
  DRAGGED_SUGGESTION_TO_CANVAS: 'Dragged Suggestion to Canvas',
  DRAGGED_WORKLOG_AROUND_CANVAS: 'Dragged Worklog around Canvas',
  DRAGGED_ISSUE_TO_AVAILABLE_DATE_IN_WEEKS_VIEW: 'Dragged issue to available date in weeks view',
  EDIT_SUGGESTION: 'Edit Suggestion',
  EDITED_COLOR_TAG: 'Edited Color-Tag',
  EDITED_DESCRIPTION: 'Edited Description',
  EDITED_NAME: 'Edited Name',
  EDITED_PLAN_IN_FORM: 'Edited Plan in Form',
  EXPAND_SUGGESTION: 'Expand Suggestion',
  EXPANDED_CARD_ON_CANVAS: 'Expanded Card on Canvas',
  FILTER_SELECTED: 'Filter Selected',
  HIDE_PLANS: 'Hide Plans',
  HOVER_ON_ACCOUNT_SETUP: 'Hover on Account Setup',
  HOVERED_ON_UNCATEGORIZED: 'Hovered on Uncategorized',
  IMPORT_FROM_JIRA: 'Import from Jira',
  IN_PROGRESS_ISSUES: 'In Progress Issues',
  INLINE_EDITING: 'Inline Editing',
  INTERACTIVE_FORM: 'Interactive Form',
  LOG_ALL_SUGGESTIONS: 'Log All Suggestions',
  LOG_SUGGESTION: 'Log Suggestion',
  MODIFY_NAME: 'Modify Name',
  MODIFY_PLAN: 'Modify Plan',
  MOVED_CARD_ON_CANVAS: 'Moved Card on Canvas',
  NEW_PLAN: 'New Plan',
  ONBOARDING_MESSAGE: 'Onboarding Message',
  OPENED_PUSH_NOTIFICATION: 'Opened Push Notification',
  PERIOD_CELL_SELECT: 'Period Cell Select',
  PIN: 'Pin',
  PRESSED_KEYBOARD_SHORTCUT: 'Pressed Keyboard Shortcut',
  REASSIGN_PLANS: 'Reassign Plans',
  REMOVE: 'Remove',
  RESIZED_PLAN: 'Resized Plan',
  SELECTED_ISSUE_FOR_TRACKER: 'Selected Issue For Tracker',
  SELECTED_SPECIFIC_DATES: 'Selected Specific Dates',
  SELECTED_TEAM: 'Selected Team',
  SELECTED_FROM_DROPDOWN_MENU: 'Selected from Dropdown Menu',
  SPLIT_PLAN: 'Split Plan',
  STARTED_AUTOMATICALLY: 'Started Automatically',
  SUGGESTED: 'Suggested',
  SWIPE: 'Swipe',
  TURNED_OFF: 'Turned Off',
  TURNED_ON: 'Turned On',
  UNHIDE_PLANS: 'Unhide Plans',
  UNPIN: 'Unpin',
  USED_DRAG_AND_DROP: 'Used Drag and Drop',
  OPENED_PERIOD_FOR_TIMESHEET_APPROVERS: 'Opened Period for Timesheet Approvers',
  OPENED_PERIOD_FOR_ALL: 'Opened Period for All',
  CLOSED_PERIOD_FOR_ALL: 'Closed Period for All',
  URL: 'Url',
  APP_MENU: 'App menu',
  SELECTOR: 'Selector',
  SHARE_LINK: 'Share link',
  ADD: 'Add',
  MOVE: 'Move',
  COPY: 'Copy',
  GENERATOR: 'Generator',
  ADD_ISSUE_DIALOG: 'Add issue dialog',
  CREATE_FOLDER_DIALOG: 'Create folder dialog',
  SEARCH_DIALOG: 'Search dialog',
  PROJECTS: 'Projects',
  BOARDS: 'Boards',
  INLINE_CREATION: 'Inline creation',
  STATUS_OVERVIEW: 'Status overview',
  PROJECT_OVERVIEW: 'Project overview',
  TEST_RUN_SET_NOTES: 'Test run set notes',
  TEST_RUN_SET_STATUS: 'Test run set status',
  ENABLE: 'Enable',
  DISABLE: 'Disable',
  CLICK_SHARE_BUTTON: 'Click share button',
  CLICK_SHARE_TOGGLE: 'Click share toggle',
  CLICK_COPY_LINK: 'Click copy link',
  TOGGLE_AUTOMATION: 'Toggle automation',
  DELETE_SUBTREE: 'Delete subtree',
  COLLAPSE_ALL: 'Collapse all',
  EXPAND_ALL: 'Expand all',
  TOGGLE_EDIT_MODE: 'Toggle edit mode',
  EXPAND_LEVEL: 'Expand level',
  PASTE: 'Paste',
  LEVEL_DOWN: 'Level down',
  LEVEL_UP: 'Level up',
  MOVE_UP: 'Move up',
  MOVE_DOWN: 'Move down',
  CUT: 'Cut',
  COLLAPSE_NEXT_LEVEL: 'Collapse next level',
  EXPAND_NEXT_LEVEL: 'Expand next level',
  ADD_COLUMN: 'Add column',
  REMOVE_COLUMN: 'Remove column',
  UPDATE_COLUMN: 'Update column',
  PIN_COLUMN: 'Pin column',
  MOVE_COLUMN: 'Move column',
  SORT_COLUMN: 'Sort column',
  TEMPO_WORKLOG_SPEC_CHANGE: 'Tempo worklog spec change',
  TEST_RUN_SET_DISPLAY_PARAMS: 'Test run set display params',
  CREATE_BASELINE: 'Create baseline',
  REMOVE_BASELINE: 'Remove baseline',
  CREATE_DEPENDENCY: 'Create dependency',
  REMOVE_DEPENDENCY: 'Remove dependency',
  CREATE_MILESTONE: 'Create milestone',
  REMOVE_MILESTONE: 'Remove milestone',
  ADD_MARKER: 'Add marker',
  REMOVE_MARKER: 'Remove marker',
  UPDATE_MARKER: 'Update marker',
  CHANGE_BAR: 'Change bar',
  AUTO_CREATE_CONFIGURATION: 'Auto-create configuration',
  IMPORT_CONFIGURATION: 'Import configuration',
  MANUAL_UPDATE: 'Manual update',
  ADD_CARD: 'Add card',
  COLUMN_VALUE_RESOLUTION_TASK: 'Column value resolution task',
  CREATE_LINK: 'Create link',
  DELETE_LINK: 'Delete link',
  MOVE_CARD: 'Move card',
  SET_ESTIMATE_LIMIT: 'Set estimate limit',
  BACKLOG_CARD_ACTION: 'Backlog card action',
  BACKLOG_OPENED: 'Backlog opened',
  BACKLOG_REFRESH: 'Backlog refresh',
  UPDATE_BACKLOG_QUERY_TYPE: 'Update backlog query type',
  BACKLOG_LOADMORE: 'Backlog loadmore',
  SELECT_AGGREGATE_MODE: 'Select aggregate mode',
  UPDATE: 'Update',
  CREATE_STATUS: 'Create status',
  REORDER_STATUSES: 'Reorder statuses',
  UPDATE_STATUS: 'Update status',
  DELETE_STATUS: 'Delete status',
  ADDED_WORK_ATTRIBUTE: 'Added Work Attribute',
  DELETED_WORK_ATTRIBUTE: 'Deleted Work Attribute',
  MADE_REMAINING_ESTIMATE_OPTIONAL: 'Made Remaining Estimate Optional',
  MADE_REMAINING_ESTIMATE_REQUIRED: 'Made Remaining Estimate Required',
  MADE_START_AND_END_TIMES_DISABLED: 'Made Start And End Times Disabled',
  MADE_START_AND_END_TIMES_ENABLED: 'Made Start And End Times Enabled',
  MADE_START_AND_END_TIMES_FOR_PLANNING_DISABLED: 'Made Start And End Times For Planning Disabled',
  MADE_START_AND_END_TIMES_FOR_PLANNING_ENABLED: 'Made Start And End Times For Planning Enabled',
  MADE_WORKLOG_DESCRIPTION_OPTIONAL: 'Made Worklog Description Optional',
  MADE_WORKLOG_DESCRIPTION_REQUIRED: 'Made Worklog Description Required',
  EDITED_WORK_ATTRIBUTE: 'Edited Work Attribute',
  EDITED_DURATION: 'Edited Duration',
  EDITED_DATE: 'Edited Date',
  SPLIT_WORKLOG: 'Split Worklog',
  ADDED_SKILL: 'Added Skill',
  REMOVED_SKILL: 'Removed Skill',
  ADDED_TEAM_ROLE: 'Added Team Role',
  REMOVED_TEAM_ROLE: 'Removed Team Role',
  EDITED_TEAM_ROLE: 'Edited Team Role',
  SET_DEFAULT_TEAM_ROLE: 'Set Default Team Role',
  IMPORTED_ACCOUNTS: 'Imported Accounts',
  EXPORTED_ACCOUNTS: 'Exported Accounts',
  EDITED_ACCOUNT_NAME: 'Edited Account Name',
  EDITED_ACCOUNT_STATUS: 'Edited Account Status',
  EDITED_ACCOUNT_MONTHLY_BUDGET: 'Edited Account Monthly Budget',
  EDITED_ACCOUNT_CUSTOMER: 'Edited Account Customer',
  EDITED_ACCOUNT_CATEGORY: 'Edited Account Category',
  EDITED_ACCOUNT_LEAD: 'Edited Account Lead',
  EDITED_ACCOUNT_CONTACT: 'Edited Account Contact',
  ADDED_ACCOUNT_PROJECT_LINK: 'Added Account Project Link',
  REMOVED_ACCOUNT_PROJECT_LINK: 'Removed Account Project Link',
  ENABLED_ACCOUNT_GLOBAL: 'Enabled Account Global',
  DISABLED_ACCOUNT_GLOBAL: 'Disabled Account Global',
  ADDED_PERMISSION_ROLE: 'Added Permission Role',
  EDITED_PERMISSION_ROLE: 'Edited Permission Role',
  DELETED_PERMISSION_ROLE: 'Deleted Permission Role',
  CONNECT: 'Connect',
  DISCONNECT: 'Disconnect',
  HIDE_TRANSFORMATION_PANEL: 'Hide transformation panel',
  SHOW_TRANSFORMATION_PANEL: 'Show transformation panel',
  HIDE_SEARCH_BAR_PANEL: 'Hide search bar panel',
  SHOW_SEARCH_BAR_PANEL: 'Show search bar panel',
  EDITED_WORKLOAD_SCHEME: 'Edited Workload Scheme',
  EDITED_WORKLOAD_SCHEME_NAME: 'Edited Workload Scheme Name',
  EDITED_WORKLOAD_SCHEME_DESCRIPTION: 'Edited Workload Scheme Description',
  EDITED_WORKLOAD_SCHEME_ADDED_MEMBER: 'Edited Workload Scheme Added Member',
  EDITED_WORKLOAD_SCHEME_MOVED_MEMBER: 'Edited Workload Scheme Moved Member',
  EDITED_WORKLOAD_SCHEME_HOURS: 'Edited Workload Scheme Hours',
  APPLY_FOREST_ACTION: 'Apply forest action',
  EDITED_WORKLOAD_SCHEME_SET_DEFAULT: 'Edited Workload Scheme Set Default',
  EDITED_FOLDER_DIALOG: 'Edited folder dialog',
  CREATED_SKILL: 'Created Skill',
  EDITED_SKILL: 'Edited Skill',
  DELETED_SKILL: 'Deleted Skill',
  CREATED_CUSTOMER: 'Created Customer',
  EDITED_CUSTOMER: 'Edited Customer',
  DELETED_CUSTOMER: 'Deleted Customer',
  CREATED_ROLE: 'Created Role',
  EDITED_ROLE: 'Edited Role',
  EDITED_ROLE_SET_DEFAULT: 'Edited Role Set Default',
  DELETED_ROLE: 'Deleted Role',
  CREATED_CATEGORY: 'Created Category',
  EDITED_CATEGORY: 'Edited Category',
  DELETED_CATEGORY: 'Deleted Category',
  ADDED_TEAM_MEMBER: 'Added Team Member',
  EDITED_TEAM_MEMBER: 'Edited Team Member',
  DELETED_TEAM_MEMBER: 'Deleted Team Member',
  EDITED_TEAM_NAME: 'Edited Team Name',
  EDITED_TEAM_LEAD: 'Edited Team Lead',
  EDITED_TEAM_PROGRAM: 'Edited Team Program',
  EDITED_TEAM_SUMMARY: 'Edited Team Summary',
  EDITED_TEAM_TYPE: 'Edited Team Type',
  ADDED_PROJECT_LINK_TO_TEAM: 'Added Project Link to Team',
  REMOVED_PROJECT_LINK_FROM_TEAM: 'Removed Project Link from Team',
  MULTIPLE_ATTRIBUTES_CHANGED: 'Multiple Attributes Changed',
  RENAMED_PROGRAM: 'Renamed Program',
  CHANGED_PROGRAM_MANAGER: 'Changed Program Manager',
  CHANGED_PROGRAM_TEAM: 'Changed Program Team',
  SET_PROGRAM_MANAGER: 'Set Program Manager',
  HIDE_ACTIVITY_PROVIDER: 'Hide Activity Provider',
  SHOW_ACTIVITY_PROVIDER: 'Show Activity Provider',
  HIDE_INCOMPLETE_ACTIVITY: 'Hide Incomplete Activity',
  SHOW_INCOMPLETE_ACTIVITY: 'Show Incomplete Activity',
  HIDE_REJECTED_ACTIVITY: 'Hide Rejected Activity',
  SHOW_REJECTED_ACTIVITY: 'Show Rejected Activity',
  RESIZED_WORKLOG: 'Resized Worklog',
  UNPIN_COLUMN: 'Unpin column',
  FILTER_STRUCTURE_SEARCH_BAR: 'Filter structure search bar',
  INLINE_EDITING: 'Inline editing',
  CREATE_STRUCTURE: 'Create structure',
  STRUCTURE_WIZARD: 'Structure wizard',
  WELCOME_SCREEN: 'Welcome screen',
  ADDED_APPLICATION: 'Added Application',
  EDITED_APPLICATION: 'Edited Application',
  REVOKED_APPLICATION: 'Revoked Application',
  CREATED_NEW_TOKEN: 'Created New Token',
  EDITED_TOKEN: 'Edited Token',
  DELETED_TOKEN: 'Deleted Token',
  REGENERATED_TOKEN: 'Regenerated Token',
  RESIZED_WORKLOG_IN_CALENDAR_VIEW: 'Resized Worklog in Calendar View',
  UPDATED_IN_FORM: 'Updated in Form',
  GROUP_BY_TIME: 'Group by Time',
  SORT_BY_START_TIME: 'Sort by Start time',
  HIDE_WEEKENDS: 'Hide Weekends',
  UPDATE_CONFIGURATION: 'Update configuration',
  CREATE_CONFIGURATION: 'Create configuration',
  FILTERS_CLEARED: 'Filters Cleared',
  SELECT_TEAMS: 'Select teams',
  VIEW_SETTINGS_CHANGED: 'View settings changed',
  SHOW_WEEKENDS: 'Show Weekends',
  ADDED_TEAM_MEMBERS_IN_BULK: 'Added Team Members In Bulk',
  ENABLED_LOGGING_ON_CLOSED_AND_ARCHIVED_ACCOUNTS: 'Enabled Logging on Closed and Archived Accounts',
  DISABLED_LOGGING_ON_CLOSED_AND_ARCHIVED_ACCOUNTS: 'Disabled Logging on Closed and Archived Accounts',
  CHANGED_MAX_DAYS_USERS_CAN_LOG_FUTURE_WORK: 'Changed Max Days Users Can Log Future Work',
  CHANGED_MAX_HOURS_USERS_CAN_LOG_WORK_PER_DAY: 'Changed Max Hours Users Can Log Work per Day',
  ENABLE_TRANSFORMATION: 'Enable Transformation',
  DISABLE_TRANSFORMATION: 'Disable Transformation',
  ADDED_FLOATING_DAY_TO_HOLIDAY_SCHEME: 'Added Floating Day to Holiday Scheme',
  ADDED_FIXED_DAY_TO_HOLIDAY_SCHEME: 'Added Fixed Day to Holiday Scheme',
  REMOVED_FLOATING_DAY_FROM_HOLIDAY_SCHEME: 'Removed Floating Day from Holiday Scheme',
  REMOVED_FIXED_DAY_FROM_HOLIDAY_SCHEME: 'Removed Fixed Day from Holiday Scheme',
  EDITED_NAME_FOR_A_HOLIDAY_SCHEME_CONFIGURATION: 'Edited Name for a Holiday Scheme Configuration',
  EDITED_DESCRIPTION_FOR_A_HOLIDAY_SCHEME_CONFIGURATION: 'Edited Description for a Holiday Scheme Configuration',
  EDITED_DURATION_FOR_A_HOLIDAY_SCHEME_CONFIGURATION: 'Edited Duration for a Holiday Scheme Configuration',
  EDITED_DATE_FOR_A_HOLIDAY_SCHEME_CONFIGURATION: 'Edited Date for a Holiday Scheme Configuration',
  DRAGGED_WORKLOG: 'Dragged Worklog',
  SET_AS_DEFAULT: 'Set as Default',
  BACKLOG_CLOSED: 'Backlog closed',
  SELECT_DEPENDENCIES: 'Select dependencies',
  UPDATE_STRUCTURE: 'Update structure',
  APPLIED_GLOBAL_COST_RATE: 'Applied Global Cost Rate',
  DISABLED_GLOBAL_COST_RATE: 'Disabled Global Cost Rate',
  ENABLED_REVENUE_TRACKING: 'Enabled Revenue Tracking',
  DISABLED_REVENUE_TRACKING: 'Disabled Revenue Tracking',
  MODIFIED_PROJECT_BUDGET: 'Modified Project Budget',
  MODIFIED_PROJECT_TIMEFRAME: 'Modified Project Timeframe',
  REVERTED_TO_CALCULATED_TIMEFRAME: 'Reverted to Calculated Timeframe',
  MODIFIED_PROJECT_NAME: 'Modified Project Name',
  MODIFIED_PROJECT_TYPE: 'Modified Project Type',
  MODIFIED_PROJECT_CURRENCY: 'Modified Project Currency',
  MODIFIED_INDIVIDUAL_COST_RATE: 'Modified Individual Cost Rate',
  ENABLED_PROJECT_TIME_APPROVAL: 'Enabled Project Time Approval',
  DISABLED_PROJECT_TIME_APPROVAL: 'Disabled Project Time Approval',
  TAG_REMOVED_FROM_PROJECT: 'Tag Removed from Project',
  ALL_TAGS_CLEARED: 'All Tags Cleared',
  ADDED_BUDGET_MILESTONE: 'Added Budget Milestone',
  REMOVED_BUDGET_MILESTONE: 'Removed Budget Milestone',
  MODIFIED_BUDGET_MILESTONE: 'Modified Budget Milestone',
  REVERTED_TO_A_SINGLE_BUDGET: 'Reverted to a Single Budget',
  IMPORTED_PROJECT_SCOPE: 'Imported Project Scope',
  SYNCED_PROJECT_SCOPE: 'Synced Project Scope',
  CHANGED_PROJECT_SCOPE: 'Changed Project Scope',
  MODIFIED_SCOPE_ORDER: 'Modified Scope Order',
  CREATED_PROJECT_GROUP: 'Created Project Group',
  MOVED_TASKS_TO_A_GROUP: 'Moved Tasks to a Group',
  REMOVED_GROUPING: 'Removed Grouping',
  GROUPED_TASKS_BY_EPICS: 'Grouped Tasks by Epics',
  CREATED_EXPENSE: 'Created Expense',
  MODIFIED_EXPENSE: 'Modified Expense',
  DELETED_EXPENSE: 'Deleted Expense',
  USER_ADDED_TO_SHARING: 'User Added to Sharing',
  HIDE_KPI_PANEL: 'Hide KPI Panel',
  SHOW_KPI_PANEL: 'Show KPI Panel',
  ADDED_PROJECT: 'Added Project',
  REMOVED_PROJECT: 'Removed Project',
  CHANGED_TIMELINE_TIME_UNIT: 'Changed Timeline Time Unit',
  INCLUDED_COMPLETED_PROJECTS: 'Included Completed Projects',
  EXCLUDED_COMPLETED_PROJECTS: 'Excluded Completed Projects',
  TURNED_ON_PORTFOLIO_SHARING: 'Turned On Portfolio Sharing',
  TURNED_OFF_PORTFOLIO_SHARING: 'Turned Off Portfolio Sharing',
  EXPORTED_EXPENSES_ACTUALS_DATA: 'Exported Expenses Actuals Data',
  EXPORTED_TASK_ACTUALS_DATA: 'Exported Task Actuals Data',
  EXPORTED_DETAILED_ACTUALS_DATA: 'Exported Detailed Actuals Data',
  EXPORTED_PORTFOLIO_SUMMARY: 'Exported Portfolio Summary',
  GLOBAL_BILLING_RATE_CHANGED: 'Global Billing Rate Changed',
  GLOBAL_COST_RATE_CHANGED: 'Global Cost Rate Changed',
  TRANSFORMATION_APPLIED: 'Transformation applied',
  TRANSFORMATION_DISABLED: 'Transformation disabled',
  TEMPO_PLANNED_TIME_SPEC_CHANGE: 'Tempo planned time spec change',
  ACTIVATING_INNOVATION: 'Activating innovation',
  DEACTIVATING_INNOVATION: 'Deactivating innovation',
  TURNING_ON_AUTOMATIC_ENABLEMENT: 'Turning on automatic enablement',
  TURNING_OFF_AUTOMATIC_ENABLEMENT: 'Turning off automatic enablement',
  MOVE_TEAM_MEMBER: 'Move Team Member',
  MODIFIED_INDIVIDUAL_BILLING_RATE: 'Modified Individual Billing Rate',
  MODIFIED_DEFAULT_BILLING_RATE: 'Modified Default Billing Rate',
  REFRESH_PANELS: 'Refresh panels',
  MODIFIED_TEAM_MEMBER_ROLE: 'Modified Team Member Role',
  DASHBOARD_ITEM: 'Dashboard item',
  MODIFIED_PROJECT_ATTRIBUTE_VALUE: 'Modified Project Attribute Value',
  MODIFIED_PROJECT_STATUS: 'Modified Project Status',
  MODIFIED_DEFAULT_COST_RATE: 'Modified Default Cost Rate',
  TAGS_ADDED_TO_PROJECT: 'Tags Added to Project',
  USER_REMOVED_FROM_SHARING: 'User Removed from Sharing',
  PINNED_PORTFOLIO: 'Pinned Portfolio',
  UNPINNED_PORTFOLIO: 'Unpinned Portfolio',
  VIEWED_CAPEX_ACCOUNTS: 'Viewed Capex Accounts',
  VIEWED_OPEX_ACCOUNTS: 'Viewed Opex Accounts',
  VIEWED_LAST_SYNC_DETAILS: 'Viewed Last Sync Details',
  VIEWED_PORTFOLIO_DESCRIPTION: 'Viewed Portfolio Description',
  EXPORTED_DETAILED_PLANNED_DATA: 'Exported Detailed Planned Data',
  HORIZONTAL_SCROLL_ENABLED: 'Horizontal scroll enabled',
  HORIZONTAL_SCROLL_DISABLED: 'Horizontal scroll disabled',
  TEXT_WRAP_ENABLED: 'Text wrap enabled',
  TEXT_WRAP_DISABLED: 'Text wrap disabled',
  CHANGE_REPORT_DISPLAY_TO_OVERVIEW: 'Change Report Display to Overview',
  EDITED_PORTFOLIO_NAME: 'Edited Portfolio Name',
  EDITED_PORTFOLIO_DESCRIPTION: 'Edited Portfolio Description',
  EDITED_PORTFOLIO_COLOR_TAG: 'Edited Portfolio Color Tag',
  ADDED_PROJECT_ATTRIBUTE: 'Added Project Attribute',
  REMOVED_PROJECT_ATTRIBUTE: 'Removed Project Attribute',
  UPDATED_PROJECT_ATTRIBUTE: 'Updated Project Attribute',
  ADDED_TEAMS: 'Added Teams',
  REMOVED_TEAMS: 'Removed Teams',
  EXPANDED_CHART: 'Expanded Chart',
  COLLAPSED_CHART: 'Collapsed Chart',
  INPUT_VALUE_INTO_ITEM_TABLE: 'Input Value Into Item Table',
  CLICKED_ADD_ITEM_BUTTON: 'Clicked Add Item Button',
  DUPLICATED_ITEM_FROM_TABLE_VALUE: 'Duplicated Item from Table Value',
  UNDO: 'Undo',
  MADE_TEAM_SELF_MANAGED: 'Made Team Self Managed',
  MADE_TEAM_ADMINISTRATIVE: 'Made Team Administrative',
  DISABLED_PLAN_APPROVAL: 'Disabled Plan Approval',
  ENABLED_PLAN_APPROVAL: 'Enabled Plan Approval',
  ENABLED_DELETE_PLANS_WHEN_ASSOCIATED_ISSUES_ARE_DELETED: 'Enabled Delete plans when associated issues are deleted',
  DISABLED_DELETE_PLANS_WHEN_ASSOCIATED_ISSUES_ARE_DELETED: 'Disabled Delete plans when associated issues are deleted',
  ITEM_REPOSITIONED: 'Item Repositioned',
  ITEM_DETAILS_EDITED: 'Item Details Edited',
  FAVORITED: 'Favorited',
  UNFAVORITED: 'Unfavorited',
  ITEM_MOVED: 'Item Moved',
  FACTOR_WEIGHT_CHANGED: 'Factor weight changed',
  FACTOR_SET_AS_DIVISOR: 'Factor set as divisor',
  MEMBERS_ADDED: 'Members Added',
  MEMBERS_REMOVED: 'Members Removed',
  DETAILS_EDITED: 'Details Edited',
  ADDED: 'Added',
  UPDATED: 'Updated',
  DELETED: 'Deleted',
  CLICKED_ON_REVIEW_ALL: 'Clicked on Review All',
  CLICKED_ON_SKIP: 'Clicked on Skip',
  CLICKED_REVIEW_TIME: 'Clicked Review Time',
  CLICKED_SHOW_MY_WORK: 'Clicked Show My Work',
  UPDATED_IN_REPORT: 'Updated in Report',
  CHANGE_TO_CARDS: 'Change to Cards',
  CHANGE_TO_HOURS: 'Change to Hours',
  COMPRESS_ROWS: 'Compress Rows',
  EXPAND_ROWS: 'Expand Rows',
  SHOW_CALENDAR_EVENTS: 'Show Calendar Events',
  HIDE_CALENDAR_EVENTS: 'Hide Calendar Events',
  SHOW_SKILLS: 'Show Skills',
  HIDE_SKILLS: 'Hide Skills',
  SHOW_REJECTED_PLANS: 'Show Rejected Plans',
  HIDE_REJECTED_PLANS: 'Hide Rejected Plans',
  SHOW_ISSUES_SIDE_PANEL: 'Show Issues Side Panel',
  HIDE_ISSUES_SIDE_PANEL: 'Hide Issues Side Panel',
  CHANGE_TO_TOTAL_HOURS: 'Change to Total Hours',
  CHANGE_TO_AVAILABILITY: 'Change to Availability',
  EXPANDED_CARDS: 'Expanded Cards',
  COMPRESSED_CARDS: 'Compressed Cards',
  SHOW_LARGE_CARDS: 'Show Large Cards',
  HIDE_LARGE_CARDS: 'Hide Large Cards',
  HIDE_CAPACITY_INDICATORS: 'Hide Capacity Indicators',
  SHOW_CAPACITY_INDICATORS: 'Show Capacity Indicators',
  SHOW_GENERIC_PLANS: 'Show Generic Plans',
  HIDE_GENERIC_PLANS: 'Hide Generic Plans',
  ENABLED_SHOW_USERS_WITH_ZERO_HOURS: 'Enabled Show Users With Zero Hours',
  DISABLED_SHOW_USERS_WITH_ZERO_HOURS: 'Disabled Show Users With Zero Hours',
  ADDED_COLUMN: 'Added Column',
  REMOVED_COLUMN: 'Removed Column',
  CHANGED_SORTING: 'Changed Sorting',
  TEAM_PROPERTIES_CHANGED: 'Team Properties Changed',
  RENAMED_REPORT: 'Renamed Report',
  PINNED_REPORT: 'Pinned Report',
  UNPINNED_REPORT: 'Unpinned Report',
  VIEW_ALL_REPORTS: 'View All Reports',
  DEPICTION_CHANGE: 'Depiction Change',
  ON_ESCAPE: 'On ESCAPE',
  ON_ARROW_DOWN: 'On Arrow Down',
  ON_ARROW_UP: 'On Arrow Up',
  ON_ENTER: 'On ENTER',
  ON_BLUR: 'On Blur',
  NEW_ITEM_BELOW: 'New Item Below',
  NEW_SUB_ITEM: 'New Sub Item',
  NEW_SUB_ITEM_BELOW: 'New Sub Item Below',
  ROADMUNK_LINK: 'Roadmunk link',
  CHANGED_ISSUE_IN_REPORTS: 'Changed Issue in Reports',
  SET_TO_STANDARD_MODE: 'Set to Standard Mode',
  SET_TO_REMINDER_MODE: 'Set to Reminder Mode',
  SET_TO_AUTOMATIC_MODE: 'Set to Automatic Mode',
  SELECTED_PLANNER: 'Selected Planner',
  SELECTED_GANTT: 'Selected Gantt',
  NEW_ITEM: 'New Item',
  ITEM_DUPLICATED: 'Item Duplicated',
  ROADMAP_ADDED: 'Roadmap Added',
  ROADMAP_REMOVED: 'Roadmap Removed',
  UPDATE_AUTO_SYNC_SCOPE_SCHEDULE: 'Update Auto-Sync Scope Schedule',
  ENABLED_SYNC_PLANS_WITH_JIRA_ISSUES: 'Enabled Sync Plans With Jira Issues',
  DISABLED_SYNC_PLANS_WITH_JIRA_ISSUES: 'Disabled Sync Plans With Jira Issues',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  ALLOWED: 'Allowed',
  NOT_ALLOWED: 'Not Allowed',
  EDITED_SYNC_PLANS_WITH_JIRA_ISSUES: 'Edited sync plans with Jira issues',
  WRONG_DURATION: 'Wrong Duration',
  LOG_ALL_DAILY_ACTIVITIES: 'Log All Daily Activities',
  LOG_ALL_ACTIVITIES: 'Log All Activities',
  ADD_WORKLOG_MODAL_CLICKED: 'Add Worklog Modal Clicked',
  ADD_WORKLOG_HEADER_CLICKED: 'Add Worklog Header Clicked',
  REPLACED_WORKLOG: 'Replaced Worklog',
  EDITED_SYNC_TEAM_PLANS_WITH_JIRA_ISSUES: 'Edited sync team plans with Jira issues',
  APPLIED_ACCOUNT_RATES: 'Applied Account Rates',
  DISABLED_ACCOUNT_RATES: 'Disabled Account Rates',
  ASSIGNED_TO_TEAM: 'Assigned to Team',
  CLICKED_ON_ACCOUNT: 'Clicked on Account',
  CLICKED_TEMPO_SETTINGS: 'Clicked Tempo Settings',
  CLICKED_COST_RATES: 'Clicked Cost Rates',
  CLICKED_BILLING_RATES: 'Clicked Billing Rates',
  REMOVED: 'Removed',
  EXPANDED: 'Expanded',
  COLLAPSED: 'Collapsed',
  CLICKED_ON_ACADEMY: 'Clicked on Academy',
  CLICKED_ON_PROJECTS: 'Clicked on Projects',
  CLICKED_ON_LIBRARY: 'Clicked on Library',
  CLICKED_ON_PEOPLE: 'Clicked on People',
  CLICKED_ON_ADMINISTRATION: 'Clicked on Administration',
  CLICKED_ON_MY_WORK: 'Clicked on My Work',
  CLICKED_ON_MY_DISCUSSIONS: 'Clicked on My Discussions',
  CLICKED_ON_MY_FAVORITES: 'Clicked on My Favorites',
  ADDED_CHART: 'Added Chart',
  REMOVED_CHART: 'Removed Chart',
  CHANGED_BREAKDOWN_METHOD: 'Changed Breakdown Method',
  CLICKED_ON_ULTIMATE_TRIAL: 'Clicked on Ultimate Trial',
  CLICKED_ON_FAVORITE: 'Clicked on Favorite',
  CLICKED_ON_DASHBOARD_PASSPORT: 'Clicked on Dashboard Passport',
  CLICKED_ON_WORKSPACE_LINK: 'Clicked on Workspace Link',
  CLICKED_ON_PREMIUM_FEATURES_TRIAL: 'Clicked on Premium Features Trial',
  SYNCHRONIZE: 'Synchronize',
  PULLED_FROM_JIRA: 'Pulled from Jira',
  CLICKED_ON_ITERATIONS_LINK: 'Clicked on Iterations Link',
  EDIT: 'Edit',
  PINNED: 'Pinned',
  UNPINNED: 'Unpinned',
  CLICKED_PROJECT_TIME_APPROVALS: 'Clicked Project Time Approvals',
  CLICKED_HOURS: 'Clicked Hours',
  UPDATED_NAME: 'Updated Name',
  UPDATED_YEAR_END_DATE: 'Updated Year End Date',
  ALLOW_DUPLICATION_ENABLED: 'Allow Duplication Enabled',
  ALLOW_DUPLICATION_DISABLED: 'Allow Duplication Disabled',
  LOCK_COLOR_PALETTE_ENABLED: 'Lock Color Palette Enabled',
  LOCK_COLOR_PALETTE_DISABLED: 'Lock Color Palette Disabled',
  SWIMLANE_BETA_ENABLED: 'Swimlane Beta Enabled',
  SWIMLANE_BETA_DISABLED: 'Swimlane Beta Disabled',
  CLICKED_REOPEN_BUTTON: 'Clicked Reopen Button',
  CLICKED_UPGRADE: 'Clicked Upgrade',
  DUPLICATED: 'Duplicated',
  UPDATED_STORY_POINT: 'Updated Story Point',
  SET_AS_DEFAULT_WORKSPACE: 'Set as Default Workspace',
  REMOVED_STORY_POINT: 'Removed Story Point',
  ADDED_STORY_POINT: 'Added Story Point',
  SELECTED_PROJECT: 'Selected Project',
  STORY_POINT_SCHEME_DELETED: 'Story Point Scheme Deleted ',
} as const;
export type OriginActionType = typeof OriginAction;
export type OriginActionValueType = OriginActionType[keyof OriginActionType];

export const StorypointScheme = {
  _NEW: ' New',
  COPIED: 'Copied',
  EXISTING: 'Existing',
} as const;
export type StorypointSchemeType = typeof StorypointScheme;
export type StorypointSchemeValueType = StorypointSchemeType[keyof StorypointSchemeType];

let sysWebBrowser: any;
let sysWebBrowserVersion: any;
let sysOperatingSystem: any;
let sysOperatingSystemVersion: any;
let sysOriginProduct: any;
let sysOriginEcosystem: any;

export function setSystemProperties(properties: {
  webBrowser: string;
  webBrowserVersion: string;
  operatingSystem: string;
  operatingSystemVersion: string;
  originProduct: OriginProductValueType;
  originEcosystem: OriginEcosystemValueType;
}) {
  if (properties.webBrowser !== null && properties.webBrowser !== undefined) {
    sysWebBrowser = properties.webBrowser;
  }
  if (properties.webBrowserVersion !== null && properties.webBrowserVersion !== undefined) {
    sysWebBrowserVersion = properties.webBrowserVersion;
  }
  if (properties.operatingSystem !== null && properties.operatingSystem !== undefined) {
    sysOperatingSystem = properties.operatingSystem;
  }
  if (properties.operatingSystemVersion !== null && properties.operatingSystemVersion !== undefined) {
    sysOperatingSystemVersion = properties.operatingSystemVersion;
  }
  if (properties.originProduct !== null && properties.originProduct !== undefined) {
    sysOriginProduct = properties.originProduct;
  }
  if (properties.originEcosystem !== null && properties.originEcosystem !== undefined) {
    sysOriginEcosystem = properties.originEcosystem;
  }
}

let LPCloud: any;

export function initAvo(
  options: {
    env: AvoEnv;
    webDebugger?: boolean;
    webDebuggerOptions?: webDebuggerOptions;
    strict?: boolean;
    noop?: boolean;
    reportFailureAs?: 'error' | 'warn' | 'log';
    inspector?: AvoInspector;
    avoLogger?: AvoLogger;
  },
  systemProperties: {
    webBrowser: string;
    webBrowserVersion: string;
    operatingSystem: string;
    operatingSystemVersion: string;
    originProduct: OriginProductValueType;
    originEcosystem: OriginEcosystemValueType;
  },
  destinationOptions: any,
  LPCloudDestination: CustomDestination
) {
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env;
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == AvoEnv.Prod) {
    InternalAvoLogger.warn('[avo] ****************************************************');
    InternalAvoLogger.warn('[avo] WARNING Avo cannot be initialized in noop mode in production:');
    InternalAvoLogger.warn('[avo] - Overwriting configuration with noop=false.');
    InternalAvoLogger.warn(
      '[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true'
    );
    InternalAvoLogger.warn('[avo] ****************************************************');
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log('[avo] ****************************************************');
    InternalAvoLogger.log('[avo] Avo is now initialized in noop mode. This means:');
    InternalAvoLogger.log('[avo] - No events will be sent');
    InternalAvoLogger.log('[avo] - No network requests are made');
    InternalAvoLogger.log('[avo] ****************************************************');
  }
  if (options.strict !== undefined) {
    __STRICT__ = options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) {
    __REPORT_FAILURE_AS__ = options.reportFailureAs;
  }
  __WEB_DEBUGGER__ =
    !__AVO_NOOP__ &&
    ((typeof window !== 'undefined' && (window as any).location.search.indexOf('avo_debug=1') > -1) ||
      (options.webDebugger !== false && __AVO_ENV__ !== AvoEnv.Prod));
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== 'prod') {
    InternalAvoLogger.warn('[avo] Avo Inspector not provided in initAvo() call');
  }

  setSystemProperties(systemProperties);

  destinationOptions = destinationOptions || {};

  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if (options.webDebuggerOptions?.position) {
      _avo_debugger_send_position(options.webDebuggerOptions.position);
    }

    (function () {
      if (typeof (window as any) === 'undefined') {
        return;
      }
      let init = function () {
        let iframe: any = document.createElement('iframe');
        document.body.appendChild(iframe);
        iframe.id = 'avo-debugger';
        iframe.src = 'https://www.avo.app/_debugger';
        iframe.style = 'display: none;';
      };

      if (document.body) {
        init();
      } else {
        document.addEventListener('DOMContentLoaded', init);
      }
    })();
  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === AvoEnv.Prod) {
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
    }
    if (__AVO_ENV__ === AvoEnv.Staging) {
    }

    LPCloud = LPCloudDestination;
    if (__AVO_ENV__ === 'prod') {
      LPCloud && LPCloud.make && LPCloud.make(__AVO_ENV__, null);
    } else if (__AVO_ENV__ === 'dev') {
      LPCloud && LPCloud.make && LPCloud.make(__AVO_ENV__, null);
    } else {
      LPCloud && LPCloud.make && LPCloud.make(__AVO_ENV__, null);
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
    }
  }
}

export function setAvoLogger(avoLogger: AvoLogger | null) {
  __AVO_LOGGER__ = avoLogger;
}

export interface IdentifiedUserProperties {
  userId_: string;
  userTimezone: string;
  userRole: string;
  emailUser: string;
  firstLogin: string;
  firstName: string;
  lastLogin: string;
  lastName: string;
  jobTitle: string;
  companySize: string;
  accountIdUser: string;
  userCountry: string;
  marketingConsent: string;
  liquidplannerLicenseType: string;
  planId: string;
  accountMemberNumber: string;
  guestUser: string;
  liquidplannerCompanyName: string;
  sfAccountId: number;
  userUtmSource: string;
  userUtmMedium: string;
  userUtmCampaign: string;
  userUtmTerm: string;
  userUtmContent: string;
  phoneUser: string;
}
/**
 * Identified User: This event is sent when a user is identified.
 *
 * @param properties the properties associatied with this event
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 * @param properties.userTimezone: User Timezone
 * @param properties.userRole: the permission role of the user
 * @param properties.emailUser: email tied to a user
 * @param properties.firstLogin: date of first login
 * @param properties.firstName: first name of user
 * @param properties.lastLogin: date of last login for the user
 * @param properties.lastName: last name of the user
 * @param properties.jobTitle: job title tied to a user
 * @param properties.companySize: size of the company user belongs to
 * @param properties.accountIdUser: account ID tied to the user
 * @param properties.userCountry: country of the user
 * @param properties.marketingConsent: marketing consent property for LP users
 * @param properties.liquidplannerLicenseType: Parameter to identify the type of plan the user is on.
 * @param properties.planId: unique identifier for a customer LP plan
 * @param properties.accountMemberNumber: user indexing based on license joining time; this value will be one for the first user who starts a trial
 * @param properties.guestUser: property to identify if the user is a guest on LP
 * @param properties.liquidplannerCompanyName: company name
 * @param properties.sfAccountId: salesforce account id
 * @param properties.userUtmSource: liquid planner UTM source
 * @param properties.userUtmMedium: liquid planner UTM medium
 * @param properties.userUtmCampaign: liquid planner UTM campaign
 * @param properties.userUtmTerm: liquid planner UTM term
 * @param properties.userUtmContent: liquid planner UTM content
 * @param properties.phoneUser: phone number shared by the user
 *
 * @see {@link https://www.avo.app/schemas/7JBjRhWGgpbREMm61E1Y/branches/-EjrmvJqg/events/D_vI8GsYA}
 */
export function identifiedUser(properties: IdentifiedUserProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'RN83SIqkD', name: 'web_browser', value: sysWebBrowser });
  eventPropertiesArray.push({ id: 'J1ceb84St', name: 'web_browser_version', value: sysWebBrowserVersion });
  eventPropertiesArray.push({ id: 'fI8onNAO9', name: 'operating_system', value: sysOperatingSystem });
  eventPropertiesArray.push({ id: '-NL_Je5eV', name: 'operating_system_version', value: sysOperatingSystemVersion });
  eventPropertiesArray.push({ id: 'Vdkm1SlEt-', name: 'origin_product', value: sysOriginProduct });
  eventPropertiesArray.push({ id: '3WwZMUPoaD', name: 'origin_ecosystem', value: sysOriginEcosystem });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  userPropertiesArray.push({ id: 'pWC73umMS', name: 'user_timezone', value: properties.userTimezone });
  userPropertiesArray.push({ id: 'SeHaN7nzr', name: 'user_role', value: properties.userRole });
  userPropertiesArray.push({ id: 'PsG9cwcrg', name: 'email_user', value: properties.emailUser });
  userPropertiesArray.push({ id: 'ZJgKVlGcP', name: 'first_login', value: properties.firstLogin });
  userPropertiesArray.push({ id: '_bkyM1Kkq', name: 'first_name', value: properties.firstName });
  userPropertiesArray.push({ id: 'XL0pgckov', name: 'last_login', value: properties.lastLogin });
  userPropertiesArray.push({ id: '7IfOYbl6I', name: 'last_name', value: properties.lastName });
  userPropertiesArray.push({ id: 'B9wi7GwW9', name: 'job_title', value: properties.jobTitle });
  userPropertiesArray.push({ id: 'DKoiUE8i_', name: 'company_size', value: properties.companySize });
  userPropertiesArray.push({ id: 'Rgql-4K7sv', name: 'account_id_user', value: properties.accountIdUser });
  userPropertiesArray.push({ id: 'lKCXYH6qM', name: 'user_country', value: properties.userCountry });
  userPropertiesArray.push({ id: 'pchJBE1OV', name: 'marketing_consent', value: properties.marketingConsent });
  userPropertiesArray.push({
    id: '2nFgBKQ63',
    name: 'liquidplanner_license_type',
    value: properties.liquidplannerLicenseType,
  });
  userPropertiesArray.push({ id: 'hWM339QnL', name: 'plan_id', value: properties.planId });
  userPropertiesArray.push({ id: 'PXnEsgb9Z', name: 'account_member_number', value: properties.accountMemberNumber });
  userPropertiesArray.push({ id: 'Rvo24IFnH', name: 'guest_user', value: properties.guestUser });
  userPropertiesArray.push({
    id: 'gmAu8fmjH',
    name: 'liquidplanner_company_name',
    value: properties.liquidplannerCompanyName,
  });
  userPropertiesArray.push({ id: 'IdR0UYZej', name: 'sf_account_id', value: properties.sfAccountId });
  userPropertiesArray.push({ id: 'mTtnmcxFxD', name: 'user_utm_source', value: properties.userUtmSource });
  userPropertiesArray.push({ id: 'T8zHx9eqOE', name: 'user_utm_medium', value: properties.userUtmMedium });
  userPropertiesArray.push({ id: '8rzrwXi8WV', name: 'user_utm_campaign', value: properties.userUtmCampaign });
  userPropertiesArray.push({ id: 'PUUsca8OcN', name: 'user_utm_term', value: properties.userUtmTerm });
  userPropertiesArray.push({ id: 'rPOnz9F6R8', name: 'user_utm_content', value: properties.userUtmContent });
  userPropertiesArray.push({ id: 'DuHt39ny6f', name: 'phone_user', value: properties.phoneUser });
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'D_vI8GsYA',
        'e8304fe11d6e9822e1e260400db30e1203dda56189c045845f94360a6adfce4d',
        messages.map((m) =>
          Object.assign(
            {},
            {
              tag: m.tag,
              propertyId: m.propertyId,
              additionalProperties: m.additionalProperties,
              actualType: m.actualType,
            }
          )
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('Identified User', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('D_vI8GsYA', 'Identified User', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  // @ts-ignore
  let eventPropertiesLPCloud: any = { ...eventProperties };
  // @ts-ignore
  let userPropertiesLPCloud: any = { ...userProperties };
  userPropertiesLPCloud['email'] = userPropertiesLPCloud['email_user'];
  delete userPropertiesLPCloud['email_user'];
  userPropertiesLPCloud['account_id'] = userPropertiesLPCloud['account_id_user'];
  delete userPropertiesLPCloud['account_id_user'];
  if (!__AVO_NOOP__) {
    // destination LPCloud
    LPCloud.identify(properties.userId_);
    LPCloud.setUserProperties(properties.userId_, (Object as any).assign({}, userPropertiesLPCloud));
  } else {
    // do nothing
  }
}

export interface PageViewedProperties {
  customDestinationPageName_: string;
  originView: OriginViewValueType | null | undefined;
  crossSellProducts: string[] | null | undefined;
}
/**
 * Page Viewed: Triggered when a user loads a page
 *
 * @param properties the properties associatied with this event
 * @param properties.customDestinationPageName_: Name of the page or screen
 * @param properties.originView: the view the user was on when they logged the time (eg. List, Time)
 * @param properties.crossSellProducts: list of cross_sell_products shown on the page
 *
 * @see {@link https://www.avo.app/schemas/7JBjRhWGgpbREMm61E1Y/branches/-EjrmvJqg/events/GvLcC7uRsO}
 */
export function pageViewed(properties: PageViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.originView !== undefined && properties.originView !== null
    ? eventPropertiesArray.push({ id: 'MxxnnG6E37aZ', name: 'origin_view', value: properties.originView })
    : eventPropertiesArray.push({ id: 'MxxnnG6E37aZ', name: 'origin_view', value: null });
  properties.crossSellProducts !== undefined && properties.crossSellProducts !== null
    ? eventPropertiesArray.push({ id: 'WYeyrF9Vs', name: 'cross_sell_products', value: properties.crossSellProducts })
    : eventPropertiesArray.push({ id: 'WYeyrF9Vs', name: 'cross_sell_products', value: null });
  eventPropertiesArray.push({ id: 'RN83SIqkD', name: 'web_browser', value: sysWebBrowser });
  eventPropertiesArray.push({ id: 'J1ceb84St', name: 'web_browser_version', value: sysWebBrowserVersion });
  eventPropertiesArray.push({ id: 'fI8onNAO9', name: 'operating_system', value: sysOperatingSystem });
  eventPropertiesArray.push({ id: '-NL_Je5eV', name: 'operating_system_version', value: sysOperatingSystemVersion });
  eventPropertiesArray.push({ id: 'Vdkm1SlEt-', name: 'origin_product', value: sysOriginProduct });
  eventPropertiesArray.push({ id: '3WwZMUPoaD', name: 'origin_ecosystem', value: sysOriginEcosystem });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'GvLcC7uRsO',
        'c9f7fb2c34a55961d9c00820a0d19a239af778ba2f8d565c539449e80c0c059e',
        messages.map((m) =>
          Object.assign(
            {},
            {
              tag: m.tag,
              propertyId: m.propertyId,
              additionalProperties: m.additionalProperties,
              actualType: m.actualType,
            }
          )
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('Page Viewed', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('GvLcC7uRsO', 'Page Viewed', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    // destination LPCloud
    if (LPCloud.logPage) {
      LPCloud.logPage(properties.customDestinationPageName_, (Object as any).assign({}, eventProperties));
    } else {
      InternalAvoLogger.error('Page method is not implemented in the destination', '');
    }
  } else {
    // do nothing
  }
}

export interface SignUpStartedProperties {
  originPage: OriginPageValueType;
  url: string;
}
/**
 * Sign Up Started: When user signs up for the first time
 *
 * @param properties the properties associatied with this event
 * @param properties.originPage: the page the user was on when they performed the action (eg. User Timesheet, JIRA Issue View)
 * @param properties.url: full URL of the page
 *
 * @see {@link https://www.avo.app/schemas/7JBjRhWGgpbREMm61E1Y/branches/-EjrmvJqg/events/q-YMGJ_-xb}
 */
export function signUpStarted(properties: SignUpStartedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: '__jqucy_Vx9b', name: 'origin_page', value: properties.originPage });
  eventPropertiesArray.push({ id: 'EB-j1Y-h7Ek-', name: 'url', value: properties.url });
  eventPropertiesArray.push({ id: 'RN83SIqkD', name: 'web_browser', value: sysWebBrowser });
  eventPropertiesArray.push({ id: 'J1ceb84St', name: 'web_browser_version', value: sysWebBrowserVersion });
  eventPropertiesArray.push({ id: 'fI8onNAO9', name: 'operating_system', value: sysOperatingSystem });
  eventPropertiesArray.push({ id: '-NL_Je5eV', name: 'operating_system_version', value: sysOperatingSystemVersion });
  eventPropertiesArray.push({ id: 'Vdkm1SlEt-', name: 'origin_product', value: sysOriginProduct });
  eventPropertiesArray.push({ id: '3WwZMUPoaD', name: 'origin_ecosystem', value: sysOriginEcosystem });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'q-YMGJ_-xb',
        'f58f0c938a18e7f0831ed105ed38790d0dd6d569514e11a36313ee9078af3b54',
        messages.map((m) =>
          Object.assign(
            {},
            {
              tag: m.tag,
              propertyId: m.propertyId,
              additionalProperties: m.additionalProperties,
              actualType: m.actualType,
            }
          )
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('Sign Up Started', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('q-YMGJ_-xb', 'Sign Up Started', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'Sign Up Started',
        eventProperties,
        'q-YMGJ_-xb',
        'f58f0c938a18e7f0831ed105ed38790d0dd6d569514e11a36313ee9078af3b54'
      );
    }
    // destination LPCloud
    LPCloud.logEvent('Sign Up Started', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SignUpCompletedProperties {
  originPage: OriginPageValueType;
  url: string;
  userFirstName: string;
  userLastName: string;
  lpCompanyName: string;
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  utmTerm: string;
  utmContent: string;
  email: string;
}
/**
 * Sign Up Completed: When user successfully signs up for the first time and leaves the onboarding flow and lands on timesheets overview page (after inviting users or skipping inviting users)
 *
 * @param properties the properties associatied with this event
 * @param properties.originPage: the page the user was on when they performed the action (eg. User Timesheet, JIRA Issue View)
 * @param properties.url: full URL of the page
 * @param properties.userFirstName: first name of the user
 * @param properties.userLastName: last name of the user
 * @param properties.lpCompanyName: event property company name
 * @param properties.utmSource: utm parameter
 * @param properties.utmMedium: utm parameter
 * @param properties.utmCampaign: utm parameter
 * @param properties.utmTerm: utm parameter
 * @param properties.utmContent: no description
 * @param properties.email: input value of user email
 *
 * @see {@link https://www.avo.app/schemas/7JBjRhWGgpbREMm61E1Y/branches/-EjrmvJqg/events/F39HBkbL4}
 */
export function signUpCompleted(properties: SignUpCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: '__jqucy_Vx9b', name: 'origin_page', value: properties.originPage });
  eventPropertiesArray.push({ id: 'EB-j1Y-h7Ek-', name: 'url', value: properties.url });
  eventPropertiesArray.push({ id: '3iFSd8nHy', name: 'user_first_name', value: properties.userFirstName });
  eventPropertiesArray.push({ id: '2w91_CaN0', name: 'user_last_name', value: properties.userLastName });
  eventPropertiesArray.push({ id: '3stmyMNSH', name: 'lp_company_name', value: properties.lpCompanyName });
  eventPropertiesArray.push({ id: 'YsmYyXNgU', name: 'utm_source', value: properties.utmSource });
  eventPropertiesArray.push({ id: '7HcvNXXdV', name: 'utm_medium', value: properties.utmMedium });
  eventPropertiesArray.push({ id: 'DvajPlLKS', name: 'utm_campaign', value: properties.utmCampaign });
  eventPropertiesArray.push({ id: 'usk4kyIie', name: 'utm_term', value: properties.utmTerm });
  eventPropertiesArray.push({ id: 'cuoPsjhFn', name: 'utm_content', value: properties.utmContent });
  eventPropertiesArray.push({ id: 'T-vEVM7He', name: 'email', value: properties.email });
  eventPropertiesArray.push({ id: 'RN83SIqkD', name: 'web_browser', value: sysWebBrowser });
  eventPropertiesArray.push({ id: 'J1ceb84St', name: 'web_browser_version', value: sysWebBrowserVersion });
  eventPropertiesArray.push({ id: 'fI8onNAO9', name: 'operating_system', value: sysOperatingSystem });
  eventPropertiesArray.push({ id: '-NL_Je5eV', name: 'operating_system_version', value: sysOperatingSystemVersion });
  eventPropertiesArray.push({ id: 'Vdkm1SlEt-', name: 'origin_product', value: sysOriginProduct });
  eventPropertiesArray.push({ id: '3WwZMUPoaD', name: 'origin_ecosystem', value: sysOriginEcosystem });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'F39HBkbL4',
        'b2cc6b32d43e8fe327f952266bf8f346b20d6d7f91841c4132b3354f89dc8090',
        messages.map((m) =>
          Object.assign(
            {},
            {
              tag: m.tag,
              propertyId: m.propertyId,
              additionalProperties: m.additionalProperties,
              actualType: m.actualType,
            }
          )
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('Sign Up Completed', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('F39HBkbL4', 'Sign Up Completed', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'Sign Up Completed',
        eventProperties,
        'F39HBkbL4',
        'b2cc6b32d43e8fe327f952266bf8f346b20d6d7f91841c4132b3354f89dc8090'
      );
    }
    // destination LPCloud
    LPCloud.logEvent('Sign Up Completed', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface IntegrationStartedProperties {
  integrationName: string;
}
/**
 * Integration Started: Triggered when a user starts the integration process by choosing to "add an integration"
 *
 * @param properties the properties associatied with this event
 * @param properties.integrationName: the name of the integration being set up (eg. Jira)
 *
 * @see {@link https://www.avo.app/schemas/7JBjRhWGgpbREMm61E1Y/branches/-EjrmvJqg/events/K0ofW8dASF}
 */
export function integrationStarted(properties: IntegrationStartedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'PrUyJjrKb', name: 'integration_name', value: properties.integrationName });
  eventPropertiesArray.push({ id: 'RN83SIqkD', name: 'web_browser', value: sysWebBrowser });
  eventPropertiesArray.push({ id: 'J1ceb84St', name: 'web_browser_version', value: sysWebBrowserVersion });
  eventPropertiesArray.push({ id: 'fI8onNAO9', name: 'operating_system', value: sysOperatingSystem });
  eventPropertiesArray.push({ id: '-NL_Je5eV', name: 'operating_system_version', value: sysOperatingSystemVersion });
  eventPropertiesArray.push({ id: 'Vdkm1SlEt-', name: 'origin_product', value: sysOriginProduct });
  eventPropertiesArray.push({ id: '3WwZMUPoaD', name: 'origin_ecosystem', value: sysOriginEcosystem });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'K0ofW8dASF',
        '1962b7c5e09ffefd5f588044bad497d9f6605f5b8803083952da06239663be1c',
        messages.map((m) =>
          Object.assign(
            {},
            {
              tag: m.tag,
              propertyId: m.propertyId,
              additionalProperties: m.additionalProperties,
              actualType: m.actualType,
            }
          )
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('Integration Started', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('K0ofW8dASF', 'Integration Started', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'Integration Started',
        eventProperties,
        'K0ofW8dASF',
        '1962b7c5e09ffefd5f588044bad497d9f6605f5b8803083952da06239663be1c'
      );
    }
    // destination LPCloud
    LPCloud.logEvent('Integration Started', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface IntegrationStepCompletedProperties {
  integrationName: string;
  stepName: string;
}
/**
 * Integration Step Completed: Triggered when a user successfully proceeds to the next step in the integration process
 * 
 * @param properties the properties associatied with this event
 * @param properties.integrationName: the name of the integration being set up (eg. Jira)
 * @param properties.stepName: the name of the step in the process
OSS - name of the carousel step which the user is proceeding through
 * 
 * @see {@link https://www.avo.app/schemas/7JBjRhWGgpbREMm61E1Y/branches/-EjrmvJqg/events/J85hIGhPX}
 */
export function integrationStepCompleted(properties: IntegrationStepCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'PrUyJjrKb', name: 'integration_name', value: properties.integrationName });
  eventPropertiesArray.push({ id: 'Lwo-vRO-S', name: 'step_name', value: properties.stepName });
  eventPropertiesArray.push({ id: 'RN83SIqkD', name: 'web_browser', value: sysWebBrowser });
  eventPropertiesArray.push({ id: 'J1ceb84St', name: 'web_browser_version', value: sysWebBrowserVersion });
  eventPropertiesArray.push({ id: 'fI8onNAO9', name: 'operating_system', value: sysOperatingSystem });
  eventPropertiesArray.push({ id: '-NL_Je5eV', name: 'operating_system_version', value: sysOperatingSystemVersion });
  eventPropertiesArray.push({ id: 'Vdkm1SlEt-', name: 'origin_product', value: sysOriginProduct });
  eventPropertiesArray.push({ id: '3WwZMUPoaD', name: 'origin_ecosystem', value: sysOriginEcosystem });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'J85hIGhPX',
        '01a3c57dec9ae2a01557e4bb4dcfba53712ece886c1296f0613861dd90d974ad',
        messages.map((m) =>
          Object.assign(
            {},
            {
              tag: m.tag,
              propertyId: m.propertyId,
              additionalProperties: m.additionalProperties,
              actualType: m.actualType,
            }
          )
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('Integration Step Completed', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log(
        'J85hIGhPX',
        'Integration Step Completed',
        messages,
        eventPropertiesArray,
        userPropertiesArray,
        []
      );
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'Integration Step Completed',
        eventProperties,
        'J85hIGhPX',
        '01a3c57dec9ae2a01557e4bb4dcfba53712ece886c1296f0613861dd90d974ad'
      );
    }
    // destination LPCloud
    LPCloud.logEvent('Integration Step Completed', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface IntegrationCompletedProperties {
  integrationName: string;
  numberOfIssues: number | null | undefined;
  numberOfIssueStatuses: number | null | undefined;
  issuesCreatedAfter: number;
  oneWaySync: boolean | null | undefined;
  twoWaySync: boolean | null | undefined;
  remainingEstimate: number | null | undefined;
  numberOfPulledFields: number | null | undefined;
  numberOfPushedFields: number | null | undefined;
  pushedIssueTypes: string | null | undefined;
  costCode: number | null | undefined;
  numberOfResources: number;
  subFolderOrganization: string;
  syncIssuePriorityOrder: boolean;
  estimateType: EstimateTypeValueType;
  storypointScheme: StorypointSchemeValueType | null | undefined;
}
/**
 * Integration Completed: Triggered when the integration process is successfully completed
 *
 * @param properties the properties associatied with this event
 * @param properties.integrationName: the name of the integration being set up (eg. Jira)
 * @param properties.numberOfIssues: number of Jira issues
 * @param properties.numberOfIssueStatuses: the number of included issue statuses for the status overview generator
 * @param properties.issuesCreatedAfter: number of days between the input date and today
 * @param properties.oneWaySync: if users select one way sync
 * @param properties.twoWaySync: if users select two way sync
 * @param properties.remainingEstimate: LP: Remaining Estimate
 * @param properties.numberOfPulledFields: LP: fields pulled during Jira integration
 * @param properties.numberOfPushedFields: LP: fields pushed during Jira integration
 * @param properties.pushedIssueTypes: LP: Pushed Issue Types (list)
 * @param properties.costCode: LP:cost code to know where times goes
 * @param properties.numberOfResources: LP: Integration Completed event: number of resources added during an integration / Project Added event: Total number of resources in the workspace
 * @param properties.subFolderOrganization: LP: Jira Integration Sub Folder Organization
 * @param properties.syncIssuePriorityOrder: LP Jira integration: Liquid planner synchronize jira issue ranks with the priority order defined in liquid planner
 * @param properties.estimateType: the type of estimation (eg. time tracking, story point, count)
 * @param properties.storypointScheme: LP: Jira Integration  New/Copied/Existing
 *
 * @see {@link https://www.avo.app/schemas/7JBjRhWGgpbREMm61E1Y/branches/-EjrmvJqg/events/HLC88bD7F}
 */
export function integrationCompleted(properties: IntegrationCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'PrUyJjrKb', name: 'integration_name', value: properties.integrationName });
  properties.numberOfIssues !== undefined && properties.numberOfIssues !== null
    ? eventPropertiesArray.push({ id: 'ZXU0MMa7e', name: 'number_of_issues', value: properties.numberOfIssues })
    : eventPropertiesArray.push({ id: 'ZXU0MMa7e', name: 'number_of_issues', value: null });
  properties.numberOfIssueStatuses !== undefined && properties.numberOfIssueStatuses !== null
    ? eventPropertiesArray.push({
        id: 'v3qdy_c3r',
        name: 'number_of_issue_statuses',
        value: properties.numberOfIssueStatuses,
      })
    : eventPropertiesArray.push({ id: 'v3qdy_c3r', name: 'number_of_issue_statuses', value: null });
  eventPropertiesArray.push({ id: 'Zsry5cSu-', name: 'issues_created_after', value: properties.issuesCreatedAfter });
  properties.oneWaySync !== undefined && properties.oneWaySync !== null
    ? eventPropertiesArray.push({ id: '20kXTTfeZ', name: 'one_way_sync', value: properties.oneWaySync })
    : eventPropertiesArray.push({ id: '20kXTTfeZ', name: 'one_way_sync', value: null });
  properties.twoWaySync !== undefined && properties.twoWaySync !== null
    ? eventPropertiesArray.push({ id: 'V3RTAuR-f', name: 'two_way_sync', value: properties.twoWaySync })
    : eventPropertiesArray.push({ id: 'V3RTAuR-f', name: 'two_way_sync', value: null });
  properties.remainingEstimate !== undefined && properties.remainingEstimate !== null
    ? eventPropertiesArray.push({ id: 'hTU6AT1Mo', name: 'remaining_estimate', value: properties.remainingEstimate })
    : eventPropertiesArray.push({ id: 'hTU6AT1Mo', name: 'remaining_estimate', value: null });
  properties.numberOfPulledFields !== undefined && properties.numberOfPulledFields !== null
    ? eventPropertiesArray.push({
        id: 'MWIFwj-c_',
        name: 'number_of_pulled_fields',
        value: properties.numberOfPulledFields,
      })
    : eventPropertiesArray.push({ id: 'MWIFwj-c_', name: 'number_of_pulled_fields', value: null });
  properties.numberOfPushedFields !== undefined && properties.numberOfPushedFields !== null
    ? eventPropertiesArray.push({
        id: 'B16bZcDgz',
        name: 'number_of_pushed_fields',
        value: properties.numberOfPushedFields,
      })
    : eventPropertiesArray.push({ id: 'B16bZcDgz', name: 'number_of_pushed_fields', value: null });
  properties.pushedIssueTypes !== undefined && properties.pushedIssueTypes !== null
    ? eventPropertiesArray.push({ id: 'iVGhaafYF', name: 'pushed_issue_types', value: properties.pushedIssueTypes })
    : eventPropertiesArray.push({ id: 'iVGhaafYF', name: 'pushed_issue_types', value: null });
  properties.costCode !== undefined && properties.costCode !== null
    ? eventPropertiesArray.push({ id: 'dCSVlJKUv', name: 'cost_code', value: properties.costCode })
    : eventPropertiesArray.push({ id: 'dCSVlJKUv', name: 'cost_code', value: null });
  eventPropertiesArray.push({ id: 'yra1LRZxy', name: 'number_of_resources', value: properties.numberOfResources });
  eventPropertiesArray.push({
    id: 'QE311pBqZ',
    name: 'sub_folder_organization',
    value: properties.subFolderOrganization,
  });
  eventPropertiesArray.push({
    id: 'yWYEiNauw',
    name: 'sync_issue_priority_order',
    value: properties.syncIssuePriorityOrder,
  });
  eventPropertiesArray.push({ id: 'lO0KK7dEJ', name: 'estimate_type', value: properties.estimateType });
  properties.storypointScheme !== undefined && properties.storypointScheme !== null
    ? eventPropertiesArray.push({ id: 'wQiJSUVV2', name: 'storypoint_scheme', value: properties.storypointScheme })
    : eventPropertiesArray.push({ id: 'wQiJSUVV2', name: 'storypoint_scheme', value: null });
  eventPropertiesArray.push({ id: 'RN83SIqkD', name: 'web_browser', value: sysWebBrowser });
  eventPropertiesArray.push({ id: 'J1ceb84St', name: 'web_browser_version', value: sysWebBrowserVersion });
  eventPropertiesArray.push({ id: 'fI8onNAO9', name: 'operating_system', value: sysOperatingSystem });
  eventPropertiesArray.push({ id: '-NL_Je5eV', name: 'operating_system_version', value: sysOperatingSystemVersion });
  eventPropertiesArray.push({ id: 'Vdkm1SlEt-', name: 'origin_product', value: sysOriginProduct });
  eventPropertiesArray.push({ id: '3WwZMUPoaD', name: 'origin_ecosystem', value: sysOriginEcosystem });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'HLC88bD7F',
        'a42c4a9a997d7e5531fd4450835cce7fa3e24850898a46ededfb4bc69dde9977',
        messages.map((m) =>
          Object.assign(
            {},
            {
              tag: m.tag,
              propertyId: m.propertyId,
              additionalProperties: m.additionalProperties,
              actualType: m.actualType,
            }
          )
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('Integration Completed', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('HLC88bD7F', 'Integration Completed', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'Integration Completed',
        eventProperties,
        'HLC88bD7F',
        'a42c4a9a997d7e5531fd4450835cce7fa3e24850898a46ededfb4bc69dde9977'
      );
    }
    // destination LPCloud
    LPCloud.logEvent('Integration Completed', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface IntegrationUpdatedProperties {
  integrationName: string;
  numberOfIssueStatuses: number | null | undefined;
  issuesCreatedAfter: number | null | undefined;
  oneWaySync: boolean | null | undefined;
  twoWaySync: boolean | null | undefined;
  remainingEstimate: number | null | undefined;
  numberOfPulledFields: number | null | undefined;
  numberOfPushedFields: number | null | undefined;
  pushedIssueTypes: string | null | undefined;
  oauthCredentialsId: number | null | undefined;
  subFolderOrganization: string;
  syncIssuePriorityOrder: boolean;
}
/**
 * Integration Updated: Triggered when an existing integration's configuration is updated (eg. activating or suspending, changing sync types, etc.)
 *
 * @param properties the properties associatied with this event
 * @param properties.integrationName: the name of the integration being set up (eg. Jira)
 * @param properties.numberOfIssueStatuses: the number of included issue statuses for the status overview generator
 * @param properties.issuesCreatedAfter: number of days between the input date and today
 * @param properties.oneWaySync: if users select one way sync
 * @param properties.twoWaySync: if users select two way sync
 * @param properties.remainingEstimate: LP: Remaining Estimate
 * @param properties.numberOfPulledFields: LP: fields pulled during Jira integration
 * @param properties.numberOfPushedFields: LP: fields pushed during Jira integration
 * @param properties.pushedIssueTypes: LP: Pushed Issue Types (list)
 * @param properties.oauthCredentialsId: LP: When the user replaces authentication in integration set up
 * @param properties.subFolderOrganization: LP: Jira Integration Sub Folder Organization
 * @param properties.syncIssuePriorityOrder: LP Jira integration: Liquid planner synchronize jira issue ranks with the priority order defined in liquid planner
 *
 * @see {@link https://www.avo.app/schemas/7JBjRhWGgpbREMm61E1Y/branches/-EjrmvJqg/events/1tmPcVp-a}
 */
export function integrationUpdated(properties: IntegrationUpdatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'PrUyJjrKb', name: 'integration_name', value: properties.integrationName });
  properties.numberOfIssueStatuses !== undefined && properties.numberOfIssueStatuses !== null
    ? eventPropertiesArray.push({
        id: 'v3qdy_c3r',
        name: 'number_of_issue_statuses',
        value: properties.numberOfIssueStatuses,
      })
    : eventPropertiesArray.push({ id: 'v3qdy_c3r', name: 'number_of_issue_statuses', value: null });
  properties.issuesCreatedAfter !== undefined && properties.issuesCreatedAfter !== null
    ? eventPropertiesArray.push({ id: 'Zsry5cSu-', name: 'issues_created_after', value: properties.issuesCreatedAfter })
    : eventPropertiesArray.push({ id: 'Zsry5cSu-', name: 'issues_created_after', value: null });
  properties.oneWaySync !== undefined && properties.oneWaySync !== null
    ? eventPropertiesArray.push({ id: '20kXTTfeZ', name: 'one_way_sync', value: properties.oneWaySync })
    : eventPropertiesArray.push({ id: '20kXTTfeZ', name: 'one_way_sync', value: null });
  properties.twoWaySync !== undefined && properties.twoWaySync !== null
    ? eventPropertiesArray.push({ id: 'V3RTAuR-f', name: 'two_way_sync', value: properties.twoWaySync })
    : eventPropertiesArray.push({ id: 'V3RTAuR-f', name: 'two_way_sync', value: null });
  properties.remainingEstimate !== undefined && properties.remainingEstimate !== null
    ? eventPropertiesArray.push({ id: 'hTU6AT1Mo', name: 'remaining_estimate', value: properties.remainingEstimate })
    : eventPropertiesArray.push({ id: 'hTU6AT1Mo', name: 'remaining_estimate', value: null });
  properties.numberOfPulledFields !== undefined && properties.numberOfPulledFields !== null
    ? eventPropertiesArray.push({
        id: 'MWIFwj-c_',
        name: 'number_of_pulled_fields',
        value: properties.numberOfPulledFields,
      })
    : eventPropertiesArray.push({ id: 'MWIFwj-c_', name: 'number_of_pulled_fields', value: null });
  properties.numberOfPushedFields !== undefined && properties.numberOfPushedFields !== null
    ? eventPropertiesArray.push({
        id: 'B16bZcDgz',
        name: 'number_of_pushed_fields',
        value: properties.numberOfPushedFields,
      })
    : eventPropertiesArray.push({ id: 'B16bZcDgz', name: 'number_of_pushed_fields', value: null });
  properties.pushedIssueTypes !== undefined && properties.pushedIssueTypes !== null
    ? eventPropertiesArray.push({ id: 'iVGhaafYF', name: 'pushed_issue_types', value: properties.pushedIssueTypes })
    : eventPropertiesArray.push({ id: 'iVGhaafYF', name: 'pushed_issue_types', value: null });
  properties.oauthCredentialsId !== undefined && properties.oauthCredentialsId !== null
    ? eventPropertiesArray.push({ id: 'SHtu8cDYx', name: 'oauth_credentials_id', value: properties.oauthCredentialsId })
    : eventPropertiesArray.push({ id: 'SHtu8cDYx', name: 'oauth_credentials_id', value: null });
  eventPropertiesArray.push({
    id: 'QE311pBqZ',
    name: 'sub_folder_organization',
    value: properties.subFolderOrganization,
  });
  eventPropertiesArray.push({
    id: 'yWYEiNauw',
    name: 'sync_issue_priority_order',
    value: properties.syncIssuePriorityOrder,
  });
  eventPropertiesArray.push({ id: 'RN83SIqkD', name: 'web_browser', value: sysWebBrowser });
  eventPropertiesArray.push({ id: 'J1ceb84St', name: 'web_browser_version', value: sysWebBrowserVersion });
  eventPropertiesArray.push({ id: 'fI8onNAO9', name: 'operating_system', value: sysOperatingSystem });
  eventPropertiesArray.push({ id: '-NL_Je5eV', name: 'operating_system_version', value: sysOperatingSystemVersion });
  eventPropertiesArray.push({ id: 'Vdkm1SlEt-', name: 'origin_product', value: sysOriginProduct });
  eventPropertiesArray.push({ id: '3WwZMUPoaD', name: 'origin_ecosystem', value: sysOriginEcosystem });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        '1tmPcVp-a',
        '7acec62a6a2ed841bfb2ba4706d6eae2e28a534a7559f6023aba1f6b23849f1d',
        messages.map((m) =>
          Object.assign(
            {},
            {
              tag: m.tag,
              propertyId: m.propertyId,
              additionalProperties: m.additionalProperties,
              actualType: m.actualType,
            }
          )
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('Integration Updated', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('1tmPcVp-a', 'Integration Updated', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'Integration Updated',
        eventProperties,
        '1tmPcVp-a',
        '7acec62a6a2ed841bfb2ba4706d6eae2e28a534a7559f6023aba1f6b23849f1d'
      );
    }
    // destination LPCloud
    LPCloud.logEvent('Integration Updated', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface IntegrationSyncTriggeredProperties {
  integrationName: string;
  originAction: OriginActionValueType | null | undefined;
}
/**
 * Integration Sync Triggered: Triggered when an existing integration's configuration is updated (eg. activating or suspending, changing sync types, etc.)
 *
 * @param properties the properties associatied with this event
 * @param properties.integrationName: the name of the integration being set up (eg. Jira)
 * @param properties.originAction: how or where the action was initiated (eg. Clicked Add Item Button, Input Value Into Item Table)
 *
 * @see {@link https://www.avo.app/schemas/7JBjRhWGgpbREMm61E1Y/branches/-EjrmvJqg/events/Q66JjyHcl}
 */
export function integrationSyncTriggered(properties: IntegrationSyncTriggeredProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'PrUyJjrKb', name: 'integration_name', value: properties.integrationName });
  properties.originAction !== undefined && properties.originAction !== null
    ? eventPropertiesArray.push({ id: 'vY1-y8oZMsL2', name: 'origin_action', value: properties.originAction })
    : eventPropertiesArray.push({ id: 'vY1-y8oZMsL2', name: 'origin_action', value: null });
  eventPropertiesArray.push({ id: 'RN83SIqkD', name: 'web_browser', value: sysWebBrowser });
  eventPropertiesArray.push({ id: 'J1ceb84St', name: 'web_browser_version', value: sysWebBrowserVersion });
  eventPropertiesArray.push({ id: 'fI8onNAO9', name: 'operating_system', value: sysOperatingSystem });
  eventPropertiesArray.push({ id: '-NL_Je5eV', name: 'operating_system_version', value: sysOperatingSystemVersion });
  eventPropertiesArray.push({ id: 'Vdkm1SlEt-', name: 'origin_product', value: sysOriginProduct });
  eventPropertiesArray.push({ id: '3WwZMUPoaD', name: 'origin_ecosystem', value: sysOriginEcosystem });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'Q66JjyHcl',
        '8d5a040975ac5a5c440f1b35d20bce26ba90017e7b4e71e71a8aa4a9102aa043',
        messages.map((m) =>
          Object.assign(
            {},
            {
              tag: m.tag,
              propertyId: m.propertyId,
              additionalProperties: m.additionalProperties,
              actualType: m.actualType,
            }
          )
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('Integration Sync Triggered', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log(
        'Q66JjyHcl',
        'Integration Sync Triggered',
        messages,
        eventPropertiesArray,
        userPropertiesArray,
        []
      );
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'Integration Sync Triggered',
        eventProperties,
        'Q66JjyHcl',
        '8d5a040975ac5a5c440f1b35d20bce26ba90017e7b4e71e71a8aa4a9102aa043'
      );
    }
    // destination LPCloud
    LPCloud.logEvent('Integration Sync Triggered', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface GridFilteredProperties {
  originPage: OriginPageValueType;
  projectId: string;
  selectFilterQuery: string;
  dropDownStatusFilterSelection: string;
  workspaceId: string;
  collection: string;
  packageId: string;
  gridType: string;
}
/**
 * Grid Filtered: Triggered when a user filters a grid using the dropdown or by search
 *
 * @param properties the properties associatied with this event
 * @param properties.originPage: the page the user was on when they performed the action (eg. User Timesheet, JIRA Issue View)
 * @param properties.projectId: Unique project id
 * @param properties.selectFilterQuery: query submitted within the search filter bar
 * @param properties.dropDownStatusFilterSelection: dropdown filter selected
 * @param properties.workspaceId: ID tied to the specic workspace LiquidPlanner is deployed into
 * @param properties.collection: which collection the action is occuring (e.g, scheduled, pending, archive, template)
 * @param properties.packageId:  ID tied to the specific package which the action is occurring
 * @param properties.gridType: type of LP grid
 *
 * @see {@link https://www.avo.app/schemas/7JBjRhWGgpbREMm61E1Y/branches/-EjrmvJqg/events/sY_yXyOBdA}
 */
export function gridFiltered(properties: GridFilteredProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: '__jqucy_Vx9b', name: 'origin_page', value: properties.originPage });
  eventPropertiesArray.push({ id: 'XK9ZBe7zuo', name: 'project_id', value: properties.projectId });
  eventPropertiesArray.push({ id: 'jzkONSEj-', name: 'select_filter_query', value: properties.selectFilterQuery });
  eventPropertiesArray.push({
    id: 'DrHf1DHaz',
    name: 'drop_down_status_filter_selection',
    value: properties.dropDownStatusFilterSelection,
  });
  eventPropertiesArray.push({ id: 'YlbQFZU-P', name: 'workspace_id', value: properties.workspaceId });
  eventPropertiesArray.push({ id: 'EhaYAfCpf', name: 'collection', value: properties.collection });
  eventPropertiesArray.push({ id: 'tIKVSXrUkp', name: 'package_id', value: properties.packageId });
  eventPropertiesArray.push({ id: '2F7nRSXmy', name: 'grid_type', value: properties.gridType });
  eventPropertiesArray.push({ id: 'RN83SIqkD', name: 'web_browser', value: sysWebBrowser });
  eventPropertiesArray.push({ id: 'J1ceb84St', name: 'web_browser_version', value: sysWebBrowserVersion });
  eventPropertiesArray.push({ id: 'fI8onNAO9', name: 'operating_system', value: sysOperatingSystem });
  eventPropertiesArray.push({ id: '-NL_Je5eV', name: 'operating_system_version', value: sysOperatingSystemVersion });
  eventPropertiesArray.push({ id: 'Vdkm1SlEt-', name: 'origin_product', value: sysOriginProduct });
  eventPropertiesArray.push({ id: '3WwZMUPoaD', name: 'origin_ecosystem', value: sysOriginEcosystem });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'sY_yXyOBdA',
        '621915aa42d680ee2f2a4f8b25a61b198c24aa25cd0bfb585b6402b76e92787e',
        messages.map((m) =>
          Object.assign(
            {},
            {
              tag: m.tag,
              propertyId: m.propertyId,
              additionalProperties: m.additionalProperties,
              actualType: m.actualType,
            }
          )
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('Grid Filtered', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('sY_yXyOBdA', 'Grid Filtered', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'Grid Filtered',
        eventProperties,
        'sY_yXyOBdA',
        '621915aa42d680ee2f2a4f8b25a61b198c24aa25cd0bfb585b6402b76e92787e'
      );
    }
    // destination LPCloud
    LPCloud.logEvent('Grid Filtered', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FavoriteUpdatedProperties {
  originAction: OriginActionValueType;
  workspaceId: string;
  originPage: OriginPageValueType;
  favoriteType: string;
}
/**
 * Favorite Updated: LP: When users select a page as favortite
 *
 * @param properties the properties associatied with this event
 * @param properties.originAction: how or where the action was initiated (eg. Clicked Add Item Button, Input Value Into Item Table)
 * @param properties.workspaceId: ID tied to the specic workspace LiquidPlanner is deployed into
 * @param properties.originPage: the page the user was on when they performed the action (eg. User Timesheet, JIRA Issue View)
 * @param properties.favoriteType: LP: type of favorite
 *
 * @see {@link https://www.avo.app/schemas/7JBjRhWGgpbREMm61E1Y/branches/-EjrmvJqg/events/jFectVJI8h}
 */
export function favoriteUpdated(properties: FavoriteUpdatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'vY1-y8oZMsL2', name: 'origin_action', value: properties.originAction });
  eventPropertiesArray.push({ id: 'YlbQFZU-P', name: 'workspace_id', value: properties.workspaceId });
  eventPropertiesArray.push({ id: '__jqucy_Vx9b', name: 'origin_page', value: properties.originPage });
  eventPropertiesArray.push({ id: '1p80CP2U-', name: 'favorite_type', value: properties.favoriteType });
  eventPropertiesArray.push({ id: 'RN83SIqkD', name: 'web_browser', value: sysWebBrowser });
  eventPropertiesArray.push({ id: 'J1ceb84St', name: 'web_browser_version', value: sysWebBrowserVersion });
  eventPropertiesArray.push({ id: 'fI8onNAO9', name: 'operating_system', value: sysOperatingSystem });
  eventPropertiesArray.push({ id: '-NL_Je5eV', name: 'operating_system_version', value: sysOperatingSystemVersion });
  eventPropertiesArray.push({ id: 'Vdkm1SlEt-', name: 'origin_product', value: sysOriginProduct });
  eventPropertiesArray.push({ id: '3WwZMUPoaD', name: 'origin_ecosystem', value: sysOriginEcosystem });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'jFectVJI8h',
        'cda4138b8d94511681b45352d25cef46d1713a56c66cb5b4aeba7b549b49d271',
        messages.map((m) =>
          Object.assign(
            {},
            {
              tag: m.tag,
              propertyId: m.propertyId,
              additionalProperties: m.additionalProperties,
              actualType: m.actualType,
            }
          )
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('Favorite Updated', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('jFectVJI8h', 'Favorite Updated', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'Favorite Updated',
        eventProperties,
        'jFectVJI8h',
        'cda4138b8d94511681b45352d25cef46d1713a56c66cb5b4aeba7b549b49d271'
      );
    }
    // destination LPCloud
    LPCloud.logEvent('Favorite Updated', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LeftNavigationPaneClickedProperties {
  originAction: OriginActionValueType;
  workspaceId: string;
  originPage: OriginPageValueType;
  numberOfFavorites: number | null | undefined;
  favoriteType: string | null | undefined;
}
/**
 * Left Navigation Pane Clicked: LP: When users interact with the left navigation pane
 *
 * @param properties the properties associatied with this event
 * @param properties.originAction: how or where the action was initiated (eg. Clicked Add Item Button, Input Value Into Item Table)
 * @param properties.workspaceId: ID tied to the specic workspace LiquidPlanner is deployed into
 * @param properties.originPage: the page the user was on when they performed the action (eg. User Timesheet, JIRA Issue View)
 * @param properties.numberOfFavorites: LP: No of favorites present on the left navigation pane
 * @param properties.favoriteType: LP: type of favorite
 *
 * @see {@link https://www.avo.app/schemas/7JBjRhWGgpbREMm61E1Y/branches/-EjrmvJqg/events/0P6OYht1s}
 */
export function leftNavigationPaneClicked(properties: LeftNavigationPaneClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'vY1-y8oZMsL2', name: 'origin_action', value: properties.originAction });
  eventPropertiesArray.push({ id: 'YlbQFZU-P', name: 'workspace_id', value: properties.workspaceId });
  eventPropertiesArray.push({ id: '__jqucy_Vx9b', name: 'origin_page', value: properties.originPage });
  properties.numberOfFavorites !== undefined && properties.numberOfFavorites !== null
    ? eventPropertiesArray.push({ id: 'NrQraqyuj', name: 'number_of_favorites', value: properties.numberOfFavorites })
    : eventPropertiesArray.push({ id: 'NrQraqyuj', name: 'number_of_favorites', value: null });
  properties.favoriteType !== undefined && properties.favoriteType !== null
    ? eventPropertiesArray.push({ id: '1p80CP2U-', name: 'favorite_type', value: properties.favoriteType })
    : eventPropertiesArray.push({ id: '1p80CP2U-', name: 'favorite_type', value: null });
  eventPropertiesArray.push({ id: 'RN83SIqkD', name: 'web_browser', value: sysWebBrowser });
  eventPropertiesArray.push({ id: 'J1ceb84St', name: 'web_browser_version', value: sysWebBrowserVersion });
  eventPropertiesArray.push({ id: 'fI8onNAO9', name: 'operating_system', value: sysOperatingSystem });
  eventPropertiesArray.push({ id: '-NL_Je5eV', name: 'operating_system_version', value: sysOperatingSystemVersion });
  eventPropertiesArray.push({ id: 'Vdkm1SlEt-', name: 'origin_product', value: sysOriginProduct });
  eventPropertiesArray.push({ id: '3WwZMUPoaD', name: 'origin_ecosystem', value: sysOriginEcosystem });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        '0P6OYht1s',
        '7123a3fb1e585098d15f5209204447e8df60130880c9f46386bc5b0e5f2869a5',
        messages.map((m) =>
          Object.assign(
            {},
            {
              tag: m.tag,
              propertyId: m.propertyId,
              additionalProperties: m.additionalProperties,
              actualType: m.actualType,
            }
          )
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('Left Navigation Pane Clicked', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log(
        '0P6OYht1s',
        'Left Navigation Pane Clicked',
        messages,
        eventPropertiesArray,
        userPropertiesArray,
        []
      );
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'Left Navigation Pane Clicked',
        eventProperties,
        '0P6OYht1s',
        '7123a3fb1e585098d15f5209204447e8df60130880c9f46386bc5b0e5f2869a5'
      );
    }
    // destination LPCloud
    LPCloud.logEvent('Left Navigation Pane Clicked', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface IntegrationCancelledProperties {
  integrationName: string;
  stepName: string;
}
/**
 * Integration Cancelled: When Users cancel integration
 * 
 * @param properties the properties associatied with this event
 * @param properties.integrationName: the name of the integration being set up (eg. Jira)
 * @param properties.stepName: the name of the step in the process
OSS - name of the carousel step which the user is proceeding through
 * 
 * @see {@link https://www.avo.app/schemas/7JBjRhWGgpbREMm61E1Y/branches/-EjrmvJqg/events/F_IOyOKY2b}
 */
export function integrationCancelled(properties: IntegrationCancelledProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'PrUyJjrKb', name: 'integration_name', value: properties.integrationName });
  eventPropertiesArray.push({ id: 'Lwo-vRO-S', name: 'step_name', value: properties.stepName });
  eventPropertiesArray.push({ id: 'RN83SIqkD', name: 'web_browser', value: sysWebBrowser });
  eventPropertiesArray.push({ id: 'J1ceb84St', name: 'web_browser_version', value: sysWebBrowserVersion });
  eventPropertiesArray.push({ id: 'fI8onNAO9', name: 'operating_system', value: sysOperatingSystem });
  eventPropertiesArray.push({ id: '-NL_Je5eV', name: 'operating_system_version', value: sysOperatingSystemVersion });
  eventPropertiesArray.push({ id: 'Vdkm1SlEt-', name: 'origin_product', value: sysOriginProduct });
  eventPropertiesArray.push({ id: '3WwZMUPoaD', name: 'origin_ecosystem', value: sysOriginEcosystem });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'F_IOyOKY2b',
        'd4112fe7b93636d59e25e4b7d6c69bb6496c212a7bb9910f76665c3cd99bce15',
        messages.map((m) =>
          Object.assign(
            {},
            {
              tag: m.tag,
              propertyId: m.propertyId,
              additionalProperties: m.additionalProperties,
              actualType: m.actualType,
            }
          )
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('Integration Cancelled', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('F_IOyOKY2b', 'Integration Cancelled', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'Integration Cancelled',
        eventProperties,
        'F_IOyOKY2b',
        'd4112fe7b93636d59e25e4b7d6c69bb6496c212a7bb9910f76665c3cd99bce15'
      );
    }
    // destination LPCloud
    LPCloud.logEvent('Integration Cancelled', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ErrorReceivedProperties {
  errorName: string[];
}
/**
 * Error Received: When users receive error
 *
 * @param properties the properties associatied with this event
 * @param properties.errorName: Name of the error
 *
 * @see {@link https://www.avo.app/schemas/7JBjRhWGgpbREMm61E1Y/branches/-EjrmvJqg/events/waQPyKeywJ}
 */
export function errorReceived(properties: ErrorReceivedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({ id: 'Fmno0P0re', name: 'error_name', value: properties.errorName });
  eventPropertiesArray.push({ id: 'RN83SIqkD', name: 'web_browser', value: sysWebBrowser });
  eventPropertiesArray.push({ id: 'J1ceb84St', name: 'web_browser_version', value: sysWebBrowserVersion });
  eventPropertiesArray.push({ id: 'fI8onNAO9', name: 'operating_system', value: sysOperatingSystem });
  eventPropertiesArray.push({ id: '-NL_Je5eV', name: 'operating_system_version', value: sysOperatingSystemVersion });
  eventPropertiesArray.push({ id: 'Vdkm1SlEt-', name: 'origin_product', value: sysOriginProduct });
  eventPropertiesArray.push({ id: '3WwZMUPoaD', name: 'origin_ecosystem', value: sysOriginEcosystem });
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray);
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray);
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(
        __AVO_ENV__,
        'waQPyKeywJ',
        '0c93781483e18af974cdf6ec9d290128f133a8f7b83afea0ed523d6ae73452ab',
        messages.map((m) =>
          Object.assign(
            {},
            {
              tag: m.tag,
              propertyId: m.propertyId,
              additionalProperties: m.additionalProperties,
              actualType: m.actualType,
            }
          )
        ),
        'event'
      );
    }
    InternalAvoLogger.logEventSent('Error Received', eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log('waQPyKeywJ', 'Error Received', messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent(
        'Error Received',
        eventProperties,
        'waQPyKeywJ',
        '0c93781483e18af974cdf6ec9d290128f133a8f7b83afea0ed523d6ae73452ab'
      );
    }
    // destination LPCloud
    LPCloud.logEvent('Error Received', (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export default {
  AvoEnv,
  initAvo,
  setSystemProperties,
  avoInspectorApiKey,
  OriginEcosystem,
  OriginView,
  OriginProduct,
  OriginPage,
  EstimateType,
  OriginAction,
  StorypointScheme,
  identifiedUser,
  pageViewed,
  signUpStarted,
  signUpCompleted,
  integrationStarted,
  integrationStepCompleted,
  integrationCompleted,
  integrationUpdated,
  integrationSyncTriggered,
  gridFiltered,
  favoriteUpdated,
  leftNavigationPaneClicked,
  integrationCancelled,
  errorReceived,
};

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["identifiedUser","pageViewed","signUpStarted","signUpCompleted","integrationStarted","integrationStepCompleted","integrationCompleted","integrationUpdated","integrationSyncTriggered","gridFiltered","favoriteUpdated","leftNavigationPaneClicked","integrationCancelled","errorReceived"]
